import React from 'react'
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../models/ProvideModel";
import { Select } from "@mantine/core";
import { CommonApiEntities } from "../../../../models/enums";

const StatusCell = ({ row }) => {
    const store = useStore();
    const isCreateMode = !row.original.id
    const status = isCreateMode ? store.configManager.currentlyEditingRow?.status : row.original.status
    return (
        <Select
            data={[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" },
            ]}
            value={isCreateMode ? store.configManager.currentlyEditingRow?.status : row.original.status}
            sx={{
                ["& .mantine-Select-input"]: { backgroundColor: (status === 'Active') ? '#CCFFCC' : (status === 'Inactive') ?  '#ffcccc' : '#fff' }
              }}
            disabled={isCreateMode}
            onChange={async (val) => {
                if(isCreateMode) {
                    store.configManager.setEditingRow({ status: val }) 
                } 
                else {
                    try {
                        await store.configManager.patchEntityItem(CommonApiEntities.USERS, row.original.id, val === "Active");
                    } catch (error) {
                        console.error("Failed to patch status:", error);
                    }
                }
            }}
        />
    )
};

export default observer(StatusCell);