// models/UserModel.ts
import { types, flow, Instance, applyPatch } from 'mobx-state-tree';
import { commonFetchByEntity, commonCreateByEntity, commonUpdateByEntity, commonDeleteByEntity, commonPatchByEntity, downloadUserExcelTemplate, importUserBulkExcel, getUserPassword, } from '../api/transactionServer';
import { CommonApiEntities } from './enums';
import { getAllCustomers } from '../api/Auth';

export interface IOption {
  id: string;
  name: string;
}

export const BuilderModel = types.model('BuilderModel', {
  id: types.identifier,
  customerId: types.maybeNull(types.string),
  name: types.string,
  description: types.maybeNull(types.string),
  builderImage: types.maybeNull(types.string),
  syncCt: types.maybeNull(types.string),
  syncUt: types.maybeNull(types.string),
});

export const ProjectModel = types.model('ProjectModel', {
  id: types.identifier,
  customerId: types.maybeNull(types.string),
  name: types.string,
  description: types.maybeNull(types.string),
  status: types.optional(types.string, 'Active'),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  builderId: types.string,
  projectImage: types.maybeNull(types.string),
  syncCt: types.maybeNull(types.string),
  syncUt: types.maybeNull(types.string),
}).actions((self) => ({
  setStatus(status: 'Active' | 'Inactive') {
    applyPatch(self, { op: "replace", path: `/status`, value: status });
  }
}));

export const PropertyModel = types.model('PropertyModel', {
  id: types.identifier,
  customerId: types.maybeNull(types.string),
  name: types.string,
  address: types.maybeNull(types.string),
  status: types.optional(types.string, 'Active'),
  appStatus: types.optional(types.string, 'Active'),
  projectId: types.string,
  projectName: types.optional(types.string, ''),
  floors: types.optional(types.number, 0),
  floorDrawings: types.optional(types.frozen(), {}),
  notificationOnComplete: types.optional(types.boolean, false),
  propertyImage: types.maybeNull(types.string),
  syncCt: types.maybeNull(types.string),
  syncUt: types.maybeNull(types.string),
}).actions((self) => ({
  setStatus(status: 'Active' | 'Inactive') {
    applyPatch(self, { op: "replace", path: `/status`, value: status });
  }
}));

export const BlockModel = types.model('BlockModel', {
  id: types.identifier,
  customerId: types.maybeNull(types.string),
  name: types.string,
  description: types.maybeNull(types.string),
  propertyId: types.string,
  syncCt: types.maybeNull(types.string),
  syncUt: types.maybeNull(types.string),
});

export const UserModel = types.model('UserModel', {
  id: types.identifier,
  customerId: types.string,
  name: types.string,
  email: types.string,
  mobile: types.string,
  status: types.optional(types.string, 'Active'),
  gender: types.optional(types.string, 'M'),
  password: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  photo: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  syncCt: types.maybeNull(types.string),
  syncUt: types.maybeNull(types.string),
}).actions((self) => ({
    setStatus(status: 'Active' | 'Inactive') {
      applyPatch(self, { op: "replace", path: `/status`, value: status });
    },
    setPassword(password: string) {
      applyPatch(self, { op: "replace", path: `/password`, value: password });
    }
  }))

export const ConfigManager = types
  .model('ConfigManager', {
    customers: types.array(types.frozen<IOption>()) || [],
    currentCustomer: types.optional(types.string, ''),
    currentlyEditingRow: types.maybe(types.frozen()), // Will store the modified fields
    entities: types.array(types.frozen<IOption>()) || [],
    filteredRowsCount: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    builder: types.array(BuilderModel),
    projects: types.array(ProjectModel),
    properties: types.array(PropertyModel),
    blocks: types.array(BlockModel),
    users: types.array(UserModel),
    lastBulkImportInfo: types.maybe(types.frozen())
  })
  .views((self) => ({
    get customerName() {
      return self.customers.find(c => c.id === self.currentCustomer)?.name
    },
    get propertyOptionsWithProject(): { label: string; value: string; group?: string }[] {
      return self.properties.map((property) => {
        const project = self.projects.find(p => p.id === property.projectId);
        return {
          value: property.id,
          label: `${property.name}`,
          group: project ? project.name : 'Unknown Project',
        };
      });
    },
  })).actions((self) => ({
    initializeEntities() {
      const entitiesList = [
        { id: 'builder', name: 'Builder' },
        { id: 'project', name: 'Projects' },
        { id: 'property', name: 'Properties' },
        { id: 'block', name: 'Blocks' },
        // { id: 'activityType', name: 'Activities' },
        { id: 'user', name: 'Users' }
      ];
      self.entities.replace(entitiesList);
    },

    fetchAllCustomers: flow(function* () {
      self.loading = true;
      try {
        const res = yield getAllCustomers()
        self.customers.replace(res)
      } catch (err) {
        console.error('Failed to fetch customers:', err);
        throw err;
      } finally {
        self.loading = false;
      }
    }),

    setCustomer(customerId: string) {
      self.currentCustomer = customerId
      self.currentlyEditingRow = {};
    },
    fetchEntityData: flow(function* (entity: CommonApiEntities) {
      self.loading = true;
      try {
        if (!self.currentCustomer) { return; }
        if (self.currentlyEditingRow) { self.currentlyEditingRow = {}; } 
        switch (entity) {
          case CommonApiEntities.BUILDER: {
            const res = yield commonFetchByEntity({ entity, filters: { customerId: self.currentCustomer } });
            if (!res.data) { self.builder.clear(); }
            else { self.builder.replace(res.data.map((u: any) => BuilderModel.create(u))); }
            break;
          }
          case CommonApiEntities.PROJECTS: {
            const res = yield commonFetchByEntity({ entity, filters: { customerId: self.currentCustomer } });
            if (!res.data) { self.projects.clear(); }
            else { 
              self.projects.replace(res.data.map((u: any) => ProjectModel.create(u))); 
              const builder = yield commonFetchByEntity({ entity: CommonApiEntities.BUILDER, filters: { customerId: self.currentCustomer } });
              if (builder.data) {
                self.builder.replace(builder.data.map((u: any) => BuilderModel.create(u)));
              }
            }
            break;
          }
          case CommonApiEntities.PROPERTIES: {
            const res = yield commonFetchByEntity({ entity, filters: { customerId: self.currentCustomer } });
            if (!res.data) { self.properties.clear(); }
            else {
              self.properties.replace(res.data.map((u: any) => PropertyModel.create(u)));
              const projects = yield commonFetchByEntity({ entity: CommonApiEntities.PROJECTS, filters: { customerId: self.currentCustomer } });
              if (projects.data) {
                self.projects.replace(projects.data.map((u: any) => ProjectModel.create(u)));
              }
            }
            break;
          }
          case CommonApiEntities.BLOCKS: {
            const res = yield commonFetchByEntity({ entity, filters: { customerId: self.currentCustomer } });
            if (!res.data) { self.blocks.clear(); }
            else {
              self.blocks.replace(res.data.map((u: any) => BlockModel.create(u)));
              const projects = yield commonFetchByEntity({ entity: CommonApiEntities.PROJECTS, filters: { customerId: self.currentCustomer } });
              if (projects.data) {
                self.projects.replace(projects.data.map((u: any) => ProjectModel.create(u)));
                const properties = yield commonFetchByEntity({ entity: CommonApiEntities.PROPERTIES, filters: { customerId: self.currentCustomer } });
                if (properties.data) {
                  self.properties.replace(properties.data.map((u: any) => PropertyModel.create(u)));
                }
              }
            }
            break;
          }
          case CommonApiEntities.USERS: {
            const res = yield commonFetchByEntity({ entity, filters: { customerId: self.currentCustomer } });
            if (!res.data) { self.users.clear(); }
            else { self.users.replace(res.data.map((u: any) => UserModel.create(u))); }
            break;
          }
          default: return
        }
      } catch (err) {
        console.error('Failed to fetch entity:', err);
        throw err;
      } finally {
        self.loading = false;
      }
    }),
  })).actions((self) => ({
    createEntityItem: flow(function* (entity: CommonApiEntities, newUserData: any) {
      try {
        yield commonCreateByEntity({ entity, data: { ...newUserData, customerId: self.currentCustomer } });
        yield self.fetchEntityData(entity);
      } catch (err) {
        console.error('Create entity failed:', err);
        throw err;
      }
    }),

    updateEntityItem: flow(function* (entity: CommonApiEntities, changedFields: any) {
      try {
        switch (entity) {
          case CommonApiEntities.BUILDER: {
            yield commonUpdateByEntity({ entity: CommonApiEntities.BUILDER, data: { ...changedFields } });
            break;
          }
          case CommonApiEntities.PROJECTS: {
            yield commonUpdateByEntity({ entity: CommonApiEntities.PROJECTS, data: { ...changedFields } });
            break;
          }
          case CommonApiEntities.PROPERTIES: {
            yield commonUpdateByEntity({ entity: CommonApiEntities.PROPERTIES, data: { ...changedFields } });
            break;
          }
          case CommonApiEntities.BLOCKS: {
            yield commonUpdateByEntity({ entity: CommonApiEntities.BLOCKS, data: { ...changedFields } });
            break;
          }
          case CommonApiEntities.USERS: {
            yield commonUpdateByEntity({ entity: CommonApiEntities.USERS, data: { ...changedFields } });
            break;
          }
          default: return
        }
        yield self.fetchEntityData(entity);
      } catch (err) {
        console.error('Update entity failed:', err);
        throw err;
      }
    }),

    deleteEntityItem: flow(function* (entity: CommonApiEntities, id: string) {
      try {
        switch (entity) {
          case CommonApiEntities.BUILDER: {
            yield commonDeleteByEntity({
              entity,
              data: { builderId: id, customerId: self.currentCustomer },
            });
            if(self.currentlyEditingRow) { self.currentlyEditingRow = {} }
            break;
          }
          case CommonApiEntities.PROJECTS: {
            yield commonDeleteByEntity({
              entity,
              data: { projectId: id, customerId: self.currentCustomer },
            });
            if(self.currentlyEditingRow) { self.currentlyEditingRow = {} }
            break;
          }
          case CommonApiEntities.PROPERTIES: {
            yield commonDeleteByEntity({
              entity,
              data: { propertyId: id, customerId: self.currentCustomer },
            });
            if(self.currentlyEditingRow) { self.currentlyEditingRow = {} }
            break;
          }
          case CommonApiEntities.BLOCKS: {
            yield commonDeleteByEntity({
              entity,
              data: { blockId: id, customerId: self.currentCustomer },
            });
            if(self.currentlyEditingRow) { self.currentlyEditingRow = {} }
            break;
          }
          case CommonApiEntities.USERS: {
            yield commonDeleteByEntity({
              entity,
              data: { userId: id, customerId: self.currentCustomer },
            });
            if(self.currentlyEditingRow) { self.currentlyEditingRow = {} }
            break;
          }
          default: return
        }
        yield self.fetchEntityData(entity);
      } catch (err) {
        console.error('Delete entity failed:', err);
        throw err;
      }
    }),

    patchEntityItem: flow(function* (entity: CommonApiEntities, id: string, enable: boolean) {
      try {
        switch (entity) {
          case CommonApiEntities.PROJECTS: {
            yield commonPatchByEntity({
              entity,
              data: { projectId: id, customerId: self.currentCustomer, enable, enableAppAccess: enable },
            });
            const project = self.projects.find(u => u.id === id);
            if (project) {
              project.setStatus(enable ? 'Active' : 'Inactive');
            }
            break;
          }
          case CommonApiEntities.PROPERTIES: {
            yield commonPatchByEntity({
              entity,
              data: { propertyId: id, customerId: self.currentCustomer, enable, enableAppAccess: enable },
            });
            const property = self.properties.find(u => u.id === id);
            if (property) {
              property.setStatus(enable ? 'Active' : 'Inactive');
            }
            break;
          }
          case CommonApiEntities.USERS: {
            yield commonPatchByEntity({
              entity,
              data: { userId: id, customerId: self.currentCustomer, enable },
            });
            const user = self.users.find(u => u.id === id);
            if (user) {
              user.setStatus(enable ? 'Active' : 'Inactive');
            }
            break;
          }
          default: return
        }
      } catch (err) {
        console.error('Patch update failed:', err);
      }
    }),

    getMasterPassword: flow(function* (entity: CommonApiEntities.USERS, id: string) {
      try {
        const { data } = yield getUserPassword({ entity, id });
        const user = self.users.find(u => u.id === id);
        if (user) {
          user.setPassword(data);
        }
      } catch (err) {
        console.error('Password update failed:', err);
      }
    }),

    setEditingRow(updatedPartialRow: any) {
      self.currentlyEditingRow = { ...self.currentlyEditingRow, ...updatedPartialRow };
    },

    resetEditingRow() {
      self.currentlyEditingRow = {};
    },

    getExcelTemplate: flow(function* getExcelTemplate() {
      try {
        const data = yield downloadUserExcelTemplate()
        // create file link in browser's memory
        const href = URL.createObjectURL(data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Bulk_Users_Creation_Format.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (err) {
        console.error('user excel template download error', err);
      }
    }),

    uploadExcel: flow(function* (customerId: string, file: File) {
      try {
        self.loading = true;
        const res = yield importUserBulkExcel({ customerId, file })
        self.lastBulkImportInfo = {}
        self.lastBulkImportInfo = { customer: customerId, customerName: self.customerName, ...res.data.data }
      } catch (err) {
        console.error('user excel upload error', err);
        throw err
      } finally {
        self.loading = false;
      }
    }),

    setFilterRowsCount(val: number) {
      self.filteredRowsCount = val;
    },

    clearData() {
      self.builder.clear(); self.projects.clear(); self.properties.clear(); self.blocks.clear(); self.users.clear();
    }
  }));


export interface IConfigManager extends Instance<typeof ConfigManager> { }
export interface IBuilderModel extends Instance<typeof BuilderModel> { }
export interface IProjectModel extends Instance<typeof ProjectModel> { }
export interface IPropertyModel extends Instance<typeof PropertyModel> { }
export interface IBlockModel extends Instance<typeof BlockModel> { }
export interface IUserModel extends Instance<typeof UserModel> { }
