import React, { useMemo } from "react";
import { Button, Group, Tooltip } from "@mantine/core";
import { MRT_ColumnDef } from "mantine-react-table";
import { Instance } from "mobx-state-tree";
import { LateStoreModel } from "../../../models/DataStore";
import { CommonApiEntities } from "../../../models/enums";
import StatusCell from "./customCells/StatusCell";
import PasswordCellEditor from "./customCells/PasswordCell";
import GenderCell from "./customCells/GenderCell";
import RevealPassword from "./customCells/RevealPassword";
import moment from "moment";

export interface BaseUserColumns {
    id: string,
    customerId?: string
    name: string,
    email: string,
    mobile: string,
    status: string,
    gender?: string,
    password: string,
    address?: string,
    photo?: string,
    title?: string,
    syncCt?: string,
    syncUt?: string,
};

export interface UserDetails extends BaseUserColumns {
    [K: string]: any;
};


export const useUserTableColumnDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<UserDetails>[] = (store) => useMemo(() => [
    {
        accessorKey: "id",
        header: "User ID",
        enableEditing: true,
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => {
            const isCreateMode = !row.original.id
            return {
                value: store.configManager.currentlyEditingRow?.id ?? row.original.id ?? "",
                disabled: !isCreateMode,
                onChange: (e) => {
                    if (isCreateMode) {
                        store.configManager.setEditingRow({ id: e.currentTarget.value });
                    }
                }
            };
        },

    },
    {
        accessorKey: "name",
        header: "Name",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.name ?? row.original.name ?? "",
            onChange: (e) => store.configManager.setEditingRow({ name: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "email",
        header: "Email",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => {
            const isCreateMode = !row.original.id
            return {
                disabled: !isCreateMode,
                value: store.configManager.currentlyEditingRow?.email ?? row.original.email ?? "",
                onChange: (e) => store.configManager.setEditingRow({ email: e.currentTarget.value }),
                error: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(store.configManager.currentlyEditingRow?.email || row.original.email),
            };
        },
    },
    {
        accessorKey: "mobile",
        header: "Mobile",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.mobile ?? row.original.mobile ?? "",
            onChange: (e) => {
                const val = e.currentTarget.value;
                if (/^\d{0,10}$/.test(val)) {
                    store.configManager.setEditingRow({ mobile: val });
                }
            },
            maxLength: 10,
        }),
    },
    {
        accessorKey: "status",
        header: "Status",
        enableEditing: false,
        enableColumnFilterModes: false,
        Cell: ({ row }) => <StatusCell row={row} />
    },
    {
        accessorKey: "password",
        header: "Password",
        enableColumnFilterModes: false,
        Cell: ({ row }) => <RevealPassword row={row}/>,
        Edit: ({ row }) => {
            const isCreateMode = !row.original.id;
            const value = store.configManager.currentlyEditingRow?.password ?? row.original.password ?? "";
            return (
                <PasswordCellEditor
                    initialValue={value}
                    onChange={(val) => {
                        store.configManager.setEditingRow({ password: val });
                        if (isCreateMode) { row._valuesCache["password"] = val }
                    }} />
            );
        },
    },
    {
        accessorKey: "gender",
        header: "Gender",
        enableColumnFilterModes: false,
        Edit: ({ row }) => <GenderCell row={row} />
    },
    {
        accessorKey: "address",
        header: "Address",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.address ?? row.original.address ?? "",
            onChange: (e) => store.configManager.setEditingRow({ address: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "title",
        header: "Title",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.title ?? row.original.title ?? "",
            onChange: (e) => store.configManager.setEditingRow({ title: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "photo",
        header: "Photo",
        enableColumnFilterModes: false,
        Cell: ({ cell }) =>
            cell.getValue() ? (
                <Tooltip label="Photo Preview">
                    <img src={cell.getValue() as string} alt="user" style={{ width: 32, height: 32 }} />
                </Tooltip>
            ) : (
                "-"
            ),
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.photo ?? row.original.photo ?? "",
            onChange: (e) => store.configManager.setEditingRow({ photo: e.currentTarget.value }),
        }),
    },
    {
            accessorKey: "syncUt",
            header: "Updated On",
            enableColumnFilterModes: false,
            enableEditing: false,
            Cell: ({ row }) => {
                const dateStr = row.original.syncUt;
                return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
            },
        },
        {
            accessorKey: "syncCt",
            header: "Created On",
            enableColumnFilterModes: false,
            enableEditing: false,
            Cell: ({ row }) => {
                const dateStr = row.original.syncCt;
                return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
            },
        },
    {
        accessorKey: "actions",
        header: "Actions",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => (
            <Group spacing="xs">
                <Button
                    size="xs"
                    color="red"
                    variant="outline"
                    disabled={!row.original.id}
                    style={{ borderRadius: 0 }}
                    onClick={() => store.configManager.deleteEntityItem(CommonApiEntities.USERS, row.original.id)}
                >
                    Delete
                </Button>
            </Group>
        ),
    },
], []);