import React, { memo, useContext, ProviderProps, createContext, useEffect } from 'react';
import { onPatch, IStateTreeNode, cast, Instance, applySnapshot, tryResolve, applyAction, IDisposer, getSnapshot, onSnapshot } from 'mobx-state-tree';
import { go } from 'csp-with-ts';
import mixpanel from 'mixpanel-browser';
import { StoreModel, IChanCommands } from './DataStore';
import { brickPalette, axis, brick, scale } from '../utils/constants';
import client from '../utils/apolloClient';
import setAuthorizationToken from '../api/setAuthorizationToken';
import config from '../configs/clientConfig';
import timingMiddleware from '../api/timeRequests';
/* import { Dashboards } from './enums'; */

export interface IStoreProviderProps extends ProviderProps<typeof StoreModel> {
  value: IStateTreeNode<typeof StoreModel>;
};


// Function to create store with listeners

export function createStore(): { disposers: IDisposer[]; msTree: IStateTreeNode<typeof StoreModel>; } {
  //const msTree = StoreModel.create({  auth: {}, params: {}, filters: {}, projectInfo: {}, summary: {}, infoPanel: {} }, { brickPalette, axis, brick, scale });
  const __init = { id: 'main', auth: {}, params: {}, filters: {}, projectInfo: {}, summary: {}, infoPanel: {}, unitActivityDetails: {}, menus: {}, userReportDetails: {}, download: {}, responsiveUtils: {}, downloadpdf: {}, exportTableFilters: {}, checklistMultiSelect: {}, notifications: {}, ticket: {bulkImport: {}}, adminModule: {}, planning: {bulkImport: {}}, activityManager: {}, configManager: {} };
  mixpanel.init(config.mixpanelToken, { ignore_dnt: true, debug: config.environment === 'prd' ? false : true });
  const env = { brickPalette, axis, brick, scale, client, mixpanel, /* firebase */ };
  let init: { [K: string]: any; } = { ...__init };

  if (localStorage.getItem('Authorization')) {
    setAuthorizationToken(localStorage.getItem('Authorization'));
    init = { ...init, auth: { isAuthenticated: true, jwtToken: localStorage.getItem('Authorization') } };
  }
  if (localStorage.getItem('towers')) {
    init = { ...init, towers: JSON.parse(localStorage.getItem('towers')!) };
  }
  if (localStorage.getItem('notificationToken')) {
    init = { ...init, notifications: { token: localStorage.getItem('notificationToken'), appId: localStorage.getItem('novuAppId') || config.novuAppId } };
  }
  if(localStorage.getItem('dprFeatures')) {
    init = {...init, planning: { ...init.planning, features : JSON.parse(localStorage.getItem('dprFeatures') || '{}')}}
  }
  if(localStorage.getItem('switchedUI')) {
    init = {...init, ticket: { ...init.ticket, switchedUI: JSON.parse(localStorage.getItem('switchedUI') || 'false') }}
  }
  let msTree = StoreModel.create({ ...init }, env);
  if (localStorage.getItem('Authorization')) {
    mixpanel.identify(msTree.auth.userId);
    mixpanel.people.set_once({
      'customerId': msTree.auth.customerId,
      'env': config.environment,
      'email': msTree.auth.email,
      'name': msTree.auth.userName
    });
    mixpanel.register({ userName: msTree.auth.userName, customerId: msTree.auth.customerId, customerName: msTree.auth.customerName, email: msTree.auth.email });
  }
  // localStorage.removeItem('refresh');
  /* if (localStorage.getItem('projectInfo')) {
   *   const val = JSON.parse(localStorage.getItem('projectInfo') || '{}')
   *   msTree.projectInfo.fillProjects(val.projects)
   *   msTree.projectInfo.setProject(val.currentProject)
   *   // = { ...init, projectInfo: {currentProject: val.currentProject} };
   * } */
  //listeners
  const clearTowersAndDashboardsOnProjectChange = onPatch(msTree.projectInfo, ({ op, path, value }, { value: oldVal }) => {
    if (['add', 'replace'].includes(op) && path === '/currentProject' && value && value !== oldVal) {
      localStorage.removeItem('tabs');
      localStorage.removeItem('towers');
      localStorage.removeItem('dprFeatures');
      msTree.refreshTowers();
      msTree.getTowers().then(() => {
        msTree.refreshDashboards();
        /* msTree.dashboards.get(Dashboards.FINISHING_TCA_LANDING)?.getDashboards() */
      });
      msTree.userReportDetails.clearUserReportDetails();
      msTree.refreshTicketModule();
      msTree.planning.setFeatureConfigs();
      /* TODO: Add code to reset Planning Module and any subsequent modules.*/
    }
  }
  );

  /* const fetchTowersOnProject = onPatch(msTree.projectInfo, ({ op, path, value }, { value: oldVal }) => {
   *   if (['add', 'replace'].includes(op) && path === '/currentProject' && value && value !== oldVal) {
   *     msTree.getTowers();
   *   }
   * }
   * ); */

  const wipeDataonLogout = onPatch(msTree.auth, ({ op, path, value }, { value: oldVal }) => {
    if (path === '/isAuthenticated' && op === 'replace' && !value && value !== oldVal) {
      if (client.client) { client.reset(); }
      setTimeout(() => { applySnapshot(msTree, { ...__init }); }, 200);
    }
  });
  //sets common header
  const getTokenFromLocal = onPatch(msTree.auth, ({ op, path, value }, { value: oldVal }) => {
    if (path === '/jwtToken' && op === 'replace' && value !== oldVal) {
      // if (client.client) { client.reset(); }
      // setTimeout(() => { applySnapshot(msTree, { ...__init }); }, 200);
      setAuthorizationToken(value);
    }
  });

  const setProjectToLocal = onPatch(msTree.projectInfo, ({ path, value }, { value: oldVal }) => {
    if (path === '/currentProject' && value && !oldVal && msTree.auth.isAuthenticated!) {
      // delete snapshot.loading;
      localStorage.setItem('currentProject', value);
      localStorage.setItem('projectInfo', JSON.stringify(getSnapshot(msTree.projectInfo)));
      mixpanel.track('ProjectChange', { project: value, info: msTree.projectInfo });
    } else if(path === '/currentProject' && value && msTree.auth.isAuthenticated!) {
      localStorage.setItem('currentProject', value);
      // localStorage.setItem('projectInfo', JSON.stringify(getSnapshot(msTree.projectInfo)));
      mixpanel.track('ProjectChange', { project: value, info: msTree.projectInfo });
    }
  })

  const loadMenu = onPatch(msTree.projectInfo, ({ path, value }) => {
    if (path === '/currentProject' && value) {
      msTree.menus.getMenu();
    }
  });
  const setTowersToLocal = onSnapshot(msTree.towers, (sn) => {
    if (sn && (sn as Array<any>).length) {
      localStorage.setItem('towers', JSON.stringify(sn));
    }
  });
  const downloadURL = onPatch(msTree.download, ({ path }) => {
    if (path === '/url') {
      applyAction(msTree.download, { name: 'getPdf', args: [] });
    }
  });

  const reportsDownload = onSnapshot(msTree.checklistMultiSelect.downloadQ, (sn) => {
    localStorage.setItem('reportsQ', JSON.stringify(sn));
  });
  const pdfReportsDownload = onSnapshot(msTree.download.downloadQ, (sn) => {
    localStorage.setItem('pdfReportsQ', JSON.stringify(sn));
  });

  const persistNovuTokens = onPatch(msTree.notifications, ({ path, value }) => {
    if (path === '/token' && !!value.length) {
      localStorage.setItem('notificationToken', value);
    }
    if (path === '/appId' && !!value.length) {
      localStorage.setItem('novuAppId', value);
    }
  });

  const notificationsQSn = onSnapshot(msTree.notifications.notificationsQ, (sn) => {
    localStorage.setItem('notificationsQ', JSON.stringify(sn));
  });

  const persistTicketBulkUploadRequests = onSnapshot(msTree.ticket.bulkImport.history, (sn) => {
    localStorage.setItem('ticketBulkUploadHistory', JSON.stringify(sn));
  });

  const persistUnitActivitytBulkUploadRequests = onSnapshot(msTree.planning.bulkImport.history, (sn) => {
    localStorage.setItem('unitActivityBulkUploadHistory', JSON.stringify(sn));
  });
  // dev code
  if (process.env.NODE_ENV !== 'production') {
    try {
      const mstMiddle = require('mst-middlewares');
      const connectReduxDevtools = mstMiddle.connectReduxDevtools;
      connectReduxDevtools(require('remotedev'), msTree);
    }
    catch (err) { console.log('failed safely when trying to access dev tools'); console.error(err); }
  }
  timingMiddleware(['reports/exports/xlsx'].map(v => config.RestApiUrl + v), ({ url, data, duration }) => {
    mixpanel.track('DownloadTime', { customer: msTree.auth.customerId, file: data.reXportPath, page: msTree.params.path, duration });
  });

  const disposers: IDisposer[] = [clearTowersAndDashboardsOnProjectChange, /* fetchTowersOnProject ,*/ wipeDataonLogout, getTokenFromLocal, setProjectToLocal, downloadURL, loadMenu, setTowersToLocal, reportsDownload, pdfReportsDownload, persistNovuTokens, notificationsQSn, persistTicketBulkUploadRequests, persistUnitActivitytBulkUploadRequests];
  return { disposers, msTree } as { disposers: IDisposer[]; msTree: IStateTreeNode<typeof StoreModel> };
};

// React Hook for main Store
const ContextStore = createContext<IStateTreeNode<typeof StoreModel> | null>(null);

const StoreProviderComponent = ({ children, value }: IStoreProviderProps) => {
  useEffect(() => go<IChanCommands>`<! ${value['channel']} ${function* () {
    while (true) {
      var v = yield;
      var node = tryResolve(value, v.path || '/');
      if (node !== null && node !== undefined) {
        if (v.prop === 'projects' && !node[v.prop].length && !node.loading) { applyAction(node, v.args ? { name: v.action, args: v.args } : { name: v.action }); }
        else if (v.prop !== 'projects' && !node.loading) { applyAction(node, v.args ? { name: v.action, args: v.args } : { name: v.action }); }
      }
    }
  }
    } `, []);
  return (
    <ContextStore.Provider value={value as IStateTreeNode<typeof StoreModel>}>
      {children}
    </ContextStore.Provider>
  );
};


export const StoreProvider = memo(StoreProviderComponent);

export function useStore(): Instance<typeof StoreModel> {
  const store = useContext(ContextStore);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('You have forgot to use StoreProvider, shame on you.');
  }
  return cast(store) as Instance<typeof StoreModel>;
}
