import React, { useState, useEffect, ChangeEvent, FormEvent, forwardRef } from 'react';
import axios from 'axios';
import { TextField, Paper, Button, FormControlLabel, Switch, } from '@material-ui/core';
import Error from '../error/ErrorPopup';
import { useStore } from '../../../models/ProvideModel';
import { observer } from 'mobx-react-lite';
import { getAxiosHeader, getImageAcceptType, millisToMinutesAndSeconds, replaceURLPlaceholders } from '../../../utils/utils';
import LoadingIntermediate from '../loading/LoadingIntermediate'
import useStyles from './FormStyles';
import { FileInput, Select as MantineSelect, MultiSelect as MantineMultiSelect, TextInput, Textarea, Switch as MantineSwitch, NumberInput } from '@mantine/core';
import { IconPhoto } from '@tabler/icons-react';
import { DatePickerInput, DateTimePicker } from '@mantine/dates';
import JsonInput from '../jsonInput/JsonInput';

interface SelectItemProps extends React.ComponentPropsWithoutRef<"div"> {
  value: string;
  label: string;
  id: string;
}

const isValidDate = (dateString: string) => {
  // Reject invalid values
  if (dateString === 'true' || dateString === 'false' || typeof (dateString) === "boolean" || dateString === "[]" || dateString === "{}" || typeof (dateString) === "number" || !dateString) {
    return false;
  }
  const date = new Date(dateString);
  // Reject invalid date formats
  if (isNaN(date.getTime())) {
    return false;
  }

  //Additional check for type string and silently getting converted to a date. 
  //Example: novus2, for this new Date() method surprisingly assumes it is november 2, 2001 just because it has characters matching the date!!!
  if(typeof(dateString) === 'string' && !isNaN(date.getTime())) {
    console.log(`dateString date getTime is NAN: ${date.getTime()}`)
    return false;
  }

  const isoString = date.toISOString();
  // Check if it's a date-only field (ends in "T18:30:00.000Z")
  // if (isoString.endsWith("T18:30:00.000Z")) {
  //   return isoString.split("T")[0]; // Remove time part
  // }

  // If the date ends in "T18:30:00.000Z", return only the date part (local date fix)
  if (isoString.endsWith("T18:30:00.000Z")) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit format
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit format
    return `${year}-${month}-${day}`;
  }
  return isoString; // Keep full string for datetime fields
}


const Form: React.FC<{
  formData: any[], // Define or import your specific types for formData, axiosConfig, etc.
  handleResponse: (json: any) => void
}> = ({ formData, handleResponse }) => {
  const store = useStore();
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const [dropdownOptions, setDropdownOptions] = useState<{ [key: string]: any[] }>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [apiError, setApiError] = useState<any | undefined>(undefined);
  const classes = useStyles()
  //   const [loading, setLoading] = useState<boolean>(false);
  const isDisabled = store.adminModule.loading
  const [jsonErrors, setJsonErrors] = useState<{ [key: string]: string }>({});

  // Create a map of dependencies for each field
  const dependenciesMap = formData.reduce((acc, field) => {
    if (field.dependentOn) {
      field.dependentOn.forEach(dependentField => {
        if (!acc[dependentField]) {
          acc[dependentField] = [];
        }
        acc[dependentField].push(field.id);
      });
    }
    return acc;
  }, {} as { [key: string]: string[] });

  // console.log('dependenciesMap', dependenciesMap)

  useEffect(() => {
    const initialValues: { [key: string]: any } = {};
    formData.forEach(field => {
      if (field.type === 'dropdown' || field.type === 'multiSelect') {
        if (field.staticOptions.length > 0) {
          setDropdownOptions(prevOptions => ({
            ...prevOptions,
            [field.id]: field.staticOptions,
          }));
        } else if (field.endpoint) {
          const placeholderRegex = /:(\w+)/g;
          if (!placeholderRegex.test(field.endpoint)) { //to avoid unncecessary api calls
            fetchDropdownOptions(field.id, field.endpoint);
          }
        }
        initialValues[field.id] = field.multiselect ? [] : '';
      } else if (field.type === 'boolean') {
        initialValues[field.id] = field.default || false;
      } else if (field.type === 'text') {
        initialValues[field.id] = field.default || '';
      } else if (field.type === 'textArray') {
        initialValues[field.id] = [];
      } else if (field.type === 'LOGIN_USER') {
        initialValues[field.id] = store.auth.userName;
      } else if (field.type === 'SET_CUSTOMER') {
        initialValues[field.id] = store.adminModule.customerId || store.auth.customerId;
      } else if (field.type === 'FILE') {
        initialValues[field.id] = null;
      } else if (field.type === 'date') {
        initialValues[field.id] = field.default || null;
      } else if (field.type === 'datetime') {
        initialValues[field.id] = field.default || null;
      } else if (field.type === 'jsonObject') {
        initialValues[field.id] = {};
      } else if (field.type === 'jsonArray') {
        initialValues[field.id] = [];
      }
    });
    setFormValues(initialValues);
    checkMandatoryFields();

    //Runs when customer is set on initial render
    formData.map(field => {
      if (field.type === 'SET_CUSTOMER') {
        setFormValues(prevValues => {
          const updatedValues = prevValues;

          // Update all dependent fields
          if (dependenciesMap[field.id]) {
            dependenciesMap[field.id].forEach(dependentFieldId => {
              const dependentField = formData.find(field => field.id === dependentFieldId);
              if (dependentField && dependentField.endpoint) {

                const endpoint = replaceURLPlaceholders(dependentField.endpoint, updatedValues);
                // console.log(`changed field ${fieldId} - dependent field ${dependentField} 's endpoint is ${endpoint}`, )
                fetchDropdownOptions(dependentFieldId, endpoint);
              } else {
                updatedValues[dependentFieldId] = ''; //set to empty
              }
            });
          }

          return updatedValues;
        });
      }
    })
  }, [formData, store.adminModule.customerId]);

  // 🚨 Track JSON validation errors
  const handleJsonValidation = (fieldId: string, isValid: boolean, errorMessage: string = '') => {
    setJsonErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (!isValid) {
        updatedErrors[fieldId] = errorMessage;
      } else {
        delete updatedErrors[fieldId]; // Remove error if valid
      }
      return updatedErrors;
    });
  };

  useEffect(() => {
    checkMandatoryFields();
  }, [formValues, jsonErrors]);

  const checkMandatoryFields = () => {
    const allMandatoryFilled = formData.every(field => {
      if (field.mandatory) {
        return formValues[field.id] !== undefined && formValues[field.id] !== '' && formValues[field.id] !== null;
      }
      return true;
    });
    setIsSubmitDisabled(!allMandatoryFilled || Object.keys(jsonErrors).length > 0);
  };


  const fetchDropdownOptions = async (fieldId: string, endpoint: string) => {
    try {
      const response = await axios.get(endpoint);
      if (response.data?.data?.length === 0 || response.data?.data == undefined) {
        const fieldName = formData.find(field => field.id === fieldId)?.name || fieldId;
        setDropdownOptions(prevOptions => ({
          ...prevOptions,
          [fieldId]: [{ id: 'no-data', name: `No ${fieldName} data found` }],
        }));
      } else {
        setDropdownOptions(prevOptions => ({
          ...prevOptions,
          [fieldId]: response.data.data,
        }));
      }
    } catch (error) {
      console.error('Failed to fetch dropdown options:', error);
    }
  };

  const handleFileChange = (event: File, fieldId: string) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [fieldId]: event
    }));
  }

  const handleChange = (event: ChangeEvent<{ value: unknown } | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, fieldId: string) => {
    const { checked, value, type } = event.target as HTMLInputElement;
    const newValue = type === 'checkbox' ? checked : type === 'textarea' ? value.split('\n').map(line => line.trim()) : value;
    // setFormValues(prevValues => ({
    //   ...prevValues,
    //   [fieldId]: newValue
    // }));

    setFormValues(prevValues => {
      const updatedValues = {
        ...prevValues,
        [fieldId]: newValue
      };

      // Update all dependent fields
      if (dependenciesMap[fieldId]) {
        dependenciesMap[fieldId].forEach(dependentFieldId => {
          const dependentField = formData.find(field => field.id === dependentFieldId);
          if (dependentField && dependentField.endpoint) {
            updatedValues[dependentFieldId] = '';
            const endpoint = replaceURLPlaceholders(dependentField.endpoint, updatedValues);
            // console.log(`changed field ${fieldId} - dependent field ${dependentField} 's endpoint is ${endpoint}`, )
            fetchDropdownOptions(dependentFieldId, endpoint);
          } else {
            updatedValues[dependentFieldId] = ''; //set to empty
          }
        });
      }

      return updatedValues;
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // setLoading(true);
    store.adminModule.updateLoading();
    const startTime = Date.now();

    try {
      let url = store.adminModule.currentRequest?.url;
      let bodyFormData = new FormData()
      if (store.adminModule.currentRequest?.input === 'QUERY') {
        const queryParams = new URLSearchParams();
        Object.entries(formValues).forEach(([key, value]) => {
          queryParams.append(key, value);
        });
        url += `?${queryParams.toString()}`;
        // console.log('url is->', url)
      } else if (store.adminModule.currentRequest?.input === 'FORM_DATA') {
        Object.entries(formValues).forEach(([key, value]) => {
            bodyFormData.append(key, value);
        });
      } else if (store.adminModule.currentRequest?.input === 'PARAM') {
        Object.entries(formValues).forEach(([key, value]) => {
          // queryParams.append(key, value);
          url += `/${value.toString()}`;
        });
      }

      const headers = { ...getAxiosHeader(store.adminModule.currentRequest?.input), ...store.adminModule.currentRequest?.meta.toJSON().headers }
      // console.log('headers',headers, 'meta', store.adminModule.currentRequest?.meta.toJSON().headers)

      const formValuesAfterNullSetForEmptyFields = Object.fromEntries(
        Object.entries(formValues).map(([key, value]) => {

          const isDate = isValidDate(value)
          if (isDate) {
            return [key, isDate]
          }
          return [key, (typeof (value) === 'string' && !value) ? null : value]
        }
        )
      );

      const startTime = Date.now();
      const response = await axios({
        method: store.adminModule.currentRequest?.method || 'POST',
        url: url,
        // headers: getAxiosHeader(store.adminModule.currentRequest?.input),
        headers,
        data: store.adminModule.currentRequest?.input === 'FORM_DATA' ? bodyFormData : formValuesAfterNullSetForEmptyFields
      });
      const duration = Date.now() - startTime
      //   setLoading(false);
      // console.log('response-response', response)
      store.adminModule.updateLoading();
      handleResponse({ ...response.data, time: millisToMinutesAndSeconds(duration) });
    } catch (error) {
      const duration = Date.now() - startTime
      //   setLoading(false);
      if (error.response) {
        if (error.response.data) {
          handleResponse({ ...error.response.data, status: error.response.status, time: millisToMinutesAndSeconds(duration) });
        } else {
          handleResponse({ ...error.message, status: error.response.status, time: millisToMinutesAndSeconds(duration) });
        }
      } else if (error.request) {
        handleResponse({ ...error.request, time: millisToMinutesAndSeconds(duration) });
      } else {
        handleResponse({ ...error.message, time: millisToMinutesAndSeconds(duration) });
      }

      store.adminModule.updateLoading();
      console.error('Form submission failed:', error);
      // setApiError(error.response?.data || "Error");

    }
  };

  // console.log('formvalues', formValues)
  return (
    <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', padding: '2em 1em', width: '90%', background: '#fffff7', borderRadius: 0 }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'start' }}>
          {formData.slice().sort((a: any, b: any) => (a.displayOrder ?? Number.MAX_VALUE) - (b.displayOrder ?? Number.MAX_VALUE)).map((field, index) => (
            <div key={field.id} className={classes.field} style={{ width: '45%' }}>
              {/* {field.type === 'text' && (
                <TextField
                  disabled={isDisabled || field.readOnly}
                  fullWidth
                  id="outlined-basic"
                  label={field.name}
                  variant="outlined"
                  name={field.id}
                  value={formValues[field.id] || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                  required={field.mandatory}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                  }}
                />
              )} */}
              {/* {(field.type === 'dropdown' || field.type === 'multiSelect') && (
                <FormControl disabled={isDisabled} fullWidth>
                  <InputLabel id={`demo-simple-select-label-${index}`}>{field.name}</InputLabel>
                  <Select
                    id={`select-${field.id}`}
                    multiple={field.multiselect}
                    value={formValues[field.id] || (field.multiselect ? [] : '')}
                    onChange={(e: ChangeEvent<{ value: unknown }>) => handleChange(e, field.id)}
                    required={field.mandatory}
                    renderValue={(selected) => {
                      if (Array.isArray(selected)) {
                        return selected.map((value) => dropdownOptions[field.id].find((option) => option.id === value)?.name).join(', ');
                      }
                      return dropdownOptions[field.id] && dropdownOptions[field.id].find((option) => option.id === selected)?.name || '';
                    }}
                  >
                    {
                      dropdownOptions[field.id]?.length === 1 && dropdownOptions[field.id][0].id === 'no-data' ? (
                        <MenuItem disabled>{dropdownOptions[field.id][0].name}</MenuItem>
                      ) : (dropdownOptions[field.id]?.map(option => (
                        <MenuItem key={option.id} value={option.id} style={{ fontSize: '0.7375em' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'start' }}>
                            <span style={{ fontSize: '1em', fontWeight: 500 }}>{option.name}</span>
                            <span style={{ fontSize: '0.8em', color: '#888', marginBottom: '1.15em', marginTop: '0.35em', lineHeight: 0 }}>{option.id}</span>
                          </div>
                        </MenuItem>
                      )))
                    }
                  </Select>
                </FormControl>
              )} */}
              {/* {field.type === 'boolean' && (
                <FormControlLabel
                  control={
                    <Switch
                      style={{ fontSize: '0.7375em' }}
                      checked={formValues[field.id] as boolean || false}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                    />
                  }
                  label={field.name}
                  disabled={isDisabled}
                />
              )} */}
              {/* {field.type === 'textArray' && (
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  id={field.id}
                  label={field.name}
                  multiline
                  rows={2}
                  variant="outlined"
                  value={formValues[field.id] ? formValues[field.id].join(',') : []}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                  required={field.mandatory}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                  }}
                />
              )} */}
              {/* {field.type === 'LOGIN_USER' && (
                <TextField
                  disabled={true}
                  fullWidth
                  id="outlined-basic"
                  label={field.name}
                  variant="outlined"
                  name={field.id}
                  value={formValues[field.id] || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                  required={field.mandatory}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                  }}
                />
              )} */}
              {/* {field.type === 'SET_CUSTOMER' && (
                <>
                  <TextField
                    disabled={true}
                    fullWidth
                    id="outlined-basic"
                    label={field.name}
                    variant="outlined"
                    name={field.id}
                    value={store.adminModule.customerId || formValues[field.id] || ''}
                    required={field.mandatory}
                    InputProps={{
                      classes: {
                        input: classes.textField,
                        disabled: classes.disabledTextField
                      },
                    }}
                  />
                </>
              )} */}
              {/* {field.type === 'number' && (
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  id="outlined-basic"
                  label={field.name}
                  variant="outlined"
                  name={field.id}
                  value={formValues[field.id] || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                  required={field.mandatory}
                  type="number"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                  }}
                />
              )} */}
              {field.type === "FILE" && (
                <FileInput
                  label={field.name}
                  disabled={isDisabled}
                  placeholder="Upload file"
                  icon={<IconPhoto size={'1.5em'} />}
                  accept={getImageAcceptType(field.allowedTypes)}
                  onChange={(e: File) => handleFileChange(e as any, field.id)}
                  required={field.mandatory}
                  sx={{
                    ["& .mantine-FileInput-label"]: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    ["& .mantine-FileInput-input"]: { fontSize: '0.8525em', marginTop: '0.5em', marginBottom: '0.5em', border: '0.0525em solid #585858', borderRadius: 0, paddingRight: '1.8575rem', '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
                    ["& .mantine-FileInput-icon"]: { justifyContent: 'center', },
                  }}
                />
              )}
              {field.type === "time" && (
                <TextField
                  id="time"
                  label={field.name}
                  disabled={isDisabled}
                  required={field.mandatory}
                  type="time"
                  value={formValues[field.id] ? formValues[field.id] : ''} // Ensure a valid string format
                  onChange={(event) => {
                    const timeString = event.target.value; // Get "HH:mm"
                    setFormValues(prevValues => ({
                      ...prevValues,
                      [field.id]: timeString || null // Ensure null if cleared
                    }));
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                    style: { fontSize: '0.8em' }
                  }}
                />
              )}
              {field.type === 'date' && (
                <DatePickerInput
                  label={field.name}
                  placeholder={`Pick a ${field.name}`}
                  disabled={isDisabled}
                  required={field.mandatory}
                  onChange={(date) => setFormValues(prevValues => ({
                    ...prevValues,
                    [field.id]: date || null // Ensure null if the date is cleared
                  }))}
                  value={formValues[field.id] || null}
                  size={'sm'}
                  sx={{
                    ["& .mantine-DatePickerInput-label"]: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    ["& .mantine-DatePickerInput-input"]: { fontSize: '0.8525em', marginTop: '0.5em', marginBottom: '0.5em', border: '0.0525em solid #585858', borderRadius: 0, paddingRight: '1.8575rem', '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
                    ["& .mantine-DatePickerInput-icon"]: { justifyContent: 'center' },
                  }}
                  style={{ alignContent: 'end' }}
                  popoverProps={{ position: 'bottom' }}
                  valueFormat={'DD/MM/YYYY'}
                  clearable
                />
              )}
              {field.type === 'datetime' && (
                <DateTimePicker
                  label={field.name}
                  placeholder='Pick a date and time'
                  disabled={isDisabled}
                  translate={'Pick a date and time'}
                  required={field.mandatory}
                  onChange={(date) => {
                    if (date) {
                      const now = new Date();
                      date.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
                    }
                    setFormValues(prevValues => ({
                      ...prevValues,
                      [field.id]: date || null // Ensure null if the date is cleared
                    }))
                  }}
                  value={formValues[field.id] || null}
                  size={'sm'}
                  sx={{
                    ["& .mantine-DateTimePicker-label"]: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    ["& .mantine-DateTimePicker-input"]: { fontSize: '0.8525em', marginTop: '0.5em', marginBottom: '0.5em', border: '0.0525em solid #585858', borderRadius: 0, paddingRight: '1.8575rem', '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
                    ["& .mantine-DateTimePicker-icon"]: { justifyContent: 'center' },
                  }}
                  popoverProps={{ position: 'bottom' }}
                />
              )}
              {/* JSON Object Field */}
              {field.type === 'jsonObject' && (
                <JsonInput field={field} value={formValues[field.id]} setFormValues={setFormValues} fieldType={field.type} handleJsonValidation={handleJsonValidation} disabled={isDisabled} />
              )}

              {/* JSON Array Field */}
              {field.type === 'jsonArray' && (
                <JsonInput field={field} value={formValues[field.id]} setFormValues={setFormValues} fieldType={field.type} handleJsonValidation={handleJsonValidation} disabled={isDisabled} />
              )}
              {(field.type === 'dropdown') && (
                <MantineSelect
                  label={field.name}
                  disabled={isDisabled}
                  placeholder={`Choose ${field.name}`}
                  data={dropdownOptions[field.id]?.map(option => ({
                    value: option.id,
                    label: option.name, // Required for searching
                    id: option.id, // Custom field for display
                  })) || []}
                  value={formValues[field.id] || (field.multiselect ? [] : '')}
                  searchable
                  clearable
                  onChange={(value) => handleChange({ target: { value } } as any, field.id)}
                  required={field.mandatory}
                  nothingFound="No options found"
                  withinPortal
                  multiple={field.multiselect}
                  maxDropdownHeight={200}
                  size="sm"
                  itemComponent={forwardRef<HTMLDivElement, SelectItemProps>(({ value, label, id, ...props }, ref) => (
                    <div ref={ref} {...props} style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
                      <span style={{ fontSize: '0.9em', fontWeight: 500 }}>{label}</span>
                      <span style={{ fontSize: '0.8em', color: '#888', marginTop: '2px' }}>{id}</span>
                    </div>
                  ))}
                  styles={{
                    label: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    input: {
                      fontSize: '0.8525em',
                      marginTop: '0.5em',
                      marginBottom: '0.5em',
                      border: '0.0525em solid #585858',
                      borderRadius: 0,
                      paddingRight: '1.8575rem',
                      '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' },
                    },
                    dropdown: { zIndex: 1000 },
                  }}
                />
              )
              }
              {(field.type === 'multiSelect') && (
                <MantineMultiSelect
                  label={field.name}
                  disabled={isDisabled}
                  placeholder={`Choose ${field.name}`}
                  data={dropdownOptions[field.id]?.map(option => ({
                    value: option.id,
                    label: option.name, // Required for searching
                    id: option.id, // Custom field for display
                  })) || []}
                  value={formValues[field.id] || (field.multiselect ? [] : '')}
                  searchable
                  clearable
                  onChange={(value) => handleChange({ target: { value } } as any, field.id)}
                  required={field.mandatory}
                  nothingFound="No options found"
                  withinPortal
                  multiple={field.multiselect}
                  maxDropdownHeight={200}
                  size="sm"
                  itemComponent={forwardRef<HTMLDivElement, SelectItemProps>(({ value, label, id, ...props }, ref) => (
                    <div ref={ref} {...props} style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
                      <span style={{ fontSize: '0.9em', fontWeight: 500 }}>{label}</span>
                      <span style={{ fontSize: '0.8em', color: '#888', marginTop: '2px' }}>{id}</span>
                    </div>
                  ))}
                  styles={{
                    label: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    input: {
                      fontSize: '0.8525em',
                      marginTop: '0.5em',
                      marginBottom: '0.5em',
                      border: '0.0525em solid #585858',
                      borderRadius: 0,
                      paddingRight: '1.8575rem',
                      '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' },
                    },
                    dropdown: { zIndex: 1000 },
                  }}
                />
              )
              }
              {field.type === 'text' && (
                <TextInput
                  label={field.name}
                  disabled={isDisabled}
                  value={formValues[field.id] || ''}
                  placeholder={`Enter ${field.name}`}
                  onChange={(event) => handleChange({ target: { value: event.currentTarget.value } } as any, field.id)}
                  required={field.mandatory}
                  size="sm"
                  styles={{
                    label: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    input: {
                      fontSize: '0.8525em',
                      marginTop: '0.5em',
                      marginBottom: '0.5em',
                      border: '0.0525em solid #585858',
                      borderRadius: 0,
                      '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' },
                    },
                  }}
                />
              )}
              {field.type === 'textArray' && (
                <Textarea
                  label={field.name}
                  disabled={isDisabled}
                  value={(formValues[field.id] || []).join('\n')} // Convert array to newline-separated text
                  placeholder={`Enter ${field.name} (one per line)`}
                  onChange={(event) =>
                    handleChange({ target: { value: event.currentTarget.value.split('\n').map(line => line.trim()) } } as any, field.id)
                  }
                  required={field.mandatory}
                  minRows={3} // Keep at least 3 rows
                  autosize
                  size="sm"
                  styles={{
                    label: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    input: {
                      fontSize: '0.8525em',
                      marginTop: '0.5em',
                      marginBottom: '0.5em',
                      border: '0.0525em solid #585858',
                      borderRadius: 0,
                      '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' },
                    },
                  }}
                />
              )}
              {field.type === 'SET_CUSTOMER' && (
                <TextInput
                  label={field.name}
                  disabled={true}
                  value={store.adminModule.customerId || formValues[field.id] || ''}
                  placeholder={`Enter ${field.name}`}
                  onChange={(event) => handleChange({ target: { value: event.currentTarget.value } } as any, field.id)}
                  required={field.mandatory}
                  size="sm"
                  styles={{
                    label: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    input: {
                      fontSize: '0.8525em',
                      marginTop: '0.5em',
                      marginBottom: '0.5em',
                      border: '0.0525em solid #585858',
                      borderRadius: 0,
                      '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' },
                    },
                  }}
                />
              )}
              {field.type === 'boolean' && (
                <MantineSwitch
                  label={field.name}
                  disabled={isDisabled}
                  checked={formValues[field.id] || false} // Ensure a boolean value
                  onChange={(event) => handleChange({ target: { value: event.currentTarget.checked } } as any, field.id)}
                  // required={field.mandatory}
                  size="sm"
                  sx={{
                    marginTop: '2em'
                  }}
                  styles={{
                    label: { fontSize: '0.85em', color: '#585858', fontWeight: 600, cursor: 'pointer' },
                    track: { backgroundColor: isDisabled ? '#ddd' : undefined },
                    input: {
                      '&:checked + div': { backgroundColor: '#7d725d' }, // Customize checked color
                      '&[data-disabled] + div': { backgroundColor: '#ddd', cursor: 'not-allowed' }, // Disabled state styles
                    },
                  }}
                />
              )}
              {field.type === 'LOGIN_USER' && (
                <TextInput
                  label={field.name}
                  disabled={true}
                  value={formValues[field.id] || ''}
                  placeholder={`Enter ${field.name}`}
                  onChange={(event) => handleChange({ target: { value: event.currentTarget.value } } as any, field.id)}
                  required={field.mandatory}
                  size="sm"
                  styles={{
                    label: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    input: {
                      fontSize: '0.8525em',
                      marginTop: '0.5em',
                      marginBottom: '0.5em',
                      border: '0.0525em solid #585858',
                      borderRadius: 0,
                      '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' },
                    },
                  }}
                />
              )}
              {field.type === 'number' && (
                <NumberInput
                  label={field.name}
                  disabled={isDisabled}
                  required={field.mandatory}
                  placeholder={`Enter ${field.name}`}
                  value={formValues[field.id] ?? ''} // Handle null or undefined values properly
                  onChange={(value) => {
                    if (typeof value === 'number' || value === '') {
                      setFormValues((prev) => ({
                        ...prev,
                        [field.id]: value === '' ? null : value, // Store valid number or null
                      }));
                    }
                  }}
                  size="sm"
                  hideControls // Removes increment/decrement arrows
                  min={field.min || undefined}
                  max={field.max || undefined}
                  step={1}
                  parser={(value) => value.replace(/[^0-9]/g, '')} // 🛑 Blocks alphabets & special chars
                  formatter={(value) => String(value)} // Ensures correct display
                  styles={{
                    label: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                    input: {
                      fontSize: '0.85em',
                      marginTop: '0.5em',
                      marginBottom: '0.5em',
                      border: '0.0525em solid #585858',
                      borderRadius: 0,
                      paddingRight: '1.8575rem',
                      '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' },
                    },
                  }}
                />
              )}
              {/* {formErrors[field.id] && <p style={{ color: 'red', fontSize: '0.5em' }}>{formErrors[field.id]}</p>} */}
            </div>
          ))}
        </div>
        {/* <Button variant="contained" type="submit" disabled={isSubmitDisabled || store.adminModule.loading} className={classes.submitbutton}>{store.adminModule.loading ? 'Loading...' : 'Submit'}</Button> */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isSubmitDisabled || store.adminModule.loading} className={classes.submitbutton}>{store.adminModule.loading ? 'Loading...' : 'Submit'}</Button>
        </div>
        {/* {Object.keys(formErrors).map(e => (
          <p key={e} style={{ color: 'red', fontSize: '0.5em' }}>{e} : {formErrors[e]}</p>
        ))} */}
      </form>
      <Error response={apiError} open={!!apiError} handleClose={() => setApiError(undefined)} />
      {store.adminModule.loading ? <LoadingIntermediate /> : null}
    </Paper>

  );
};

export default observer(Form);

