import React from 'react';
import { Tooltip, } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import useStyles from './ActivityManagerStyles';

const ActivityManagerToolbar = () => {
  const classes = useStyles();
  const store = useStore();
  return (
    <div style={{ display: 'flex', gap: '8px', flexGrow: 9, flexDirection: "column" }} >
      <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, marginRight: '1em' }}>
        <div style={{ display: 'flex', flexGrow: 2 }}>
          <span style={{
            fontSize: "1.7em",
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 700,
            color: "#2F3E6C",
            // flexGrow: 2,
          }}>Activity Manager &nbsp; <span style={{ fontSize: '0.7em', color: '#C9C9C9', alignSelf: 'center' }}>{`(${store.activityManager.filteredRowsCount})`}</span></span>
          <Tooltip label={"Refresh Unit-Activities"}>
            <IconRefresh className={classes.refreshTasks} onClick={() => {
              store.activityManager.getUnitActivities()
            }} />
          </Tooltip>
        </div>
      </div>
    </div >
  );
};

export default observer(ActivityManagerToolbar);
