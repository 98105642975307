import { createStyles } from "@mantine/core";

export default createStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    width: '100%'
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    backgroundColor: theme.white,
    position: "sticky",
    top: 0,
    height: '60px',
    flexShrink: 0,
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: 600,
    margin: 0,
  },
  titlePeriod: {
    fontSize: "1rem",
    fontWeight: 600,
    color: '#005eff',
    textTransform: 'uppercase',
    marginRight: '1em'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    // flexGrow: 1,
    // overflowY: "auto",
    padding: "1rem",
    minHeight: 0
  },
  button: {
    borderRadius: 0,
    background: '#005eff',
    color: '#fff',
    '&: hover': {
      color: '#005eff'
    }
  }
}));
