import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router';
import { Button } from "@mantine/core";
import TabDetails from "../tabDetails/TabDetails";
import moment from "moment";
import { getDPRReport } from "../../../../../api/transactionServer";
import Drawer from "../../drawer/Drawer";
import { useStore } from "../../../../../models/ProvideModel";
import { ProgressReportFrequencies } from "../../../../../models/enums";
import { IconFilter } from "@tabler/icons-react";
import useStyles from './MonthlyStyles';
import LoadingSpinner from "../../../../../components/loadingSkelaton/LoadingSpinner";
import NoDataFound from "../../../../fbtReports/NoDataFound";
import { IFile } from "../../../../../containers/ticketPopup/tabItems/attachments/Attachment";

// ✅ Validate monthly format (YYYY-MM)
const isValidMonth = (monthString: string) => {
    return moment(monthString, "YYYY-MM", true).isValid();
};

const Monthly = () => {
    const { classes } = useStyles();
    const { search, pathname } = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(search);

    // Default to last month
    const lastMonth = moment().subtract(1, "month").format("YYYY-MM");

    // ✅ Validate `period` param
    let initialPeriod = params.get("period");
    if (!initialPeriod || !isValidMonth(initialPeriod)) {
        initialPeriod = lastMonth;
        params.set("period", lastMonth);
        history.replace(`${pathname}?${params.toString()}`);
    }

    const [reportData, setReportData] = useState<IFile | null>(null);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [reportMonth, setReportMonth] = useState<string>(initialPeriod);
    const store = useStore();
    const [loading, setLoading] = useState(false);
    const [allReports, setAllReports] = useState([]);

    useEffect(() => {
        async function fetchReport() {
            if (!store.projectInfo.currentProject) return;

            try {
                setLoading(true);

                const targetPeriodForReportYearsMonths = moment(reportMonth).year().toString()
                //Fetch all weeks for the month which have reports generated
                const { data: allMonthlyReportsForYear } = await getDPRReport({
                    projectId: store.projectInfo.currentProject.id,
                    targetPeriod: targetPeriodForReportYearsMonths,
                    frequency: ProgressReportFrequencies.MONTH
                });
                if (allMonthlyReportsForYear) {
                    setAllReports(allMonthlyReportsForYear.map(rep => rep.targetPeriod))
                    setReportData(allMonthlyReportsForYear.find(rep => rep.targetPeriod === reportMonth))
                }

                // setReportData(data.length ? data[0] : null);
            } catch (error) {
                console.error("Error fetching monthly report:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchReport();
    }, [reportMonth]);

    const handleMonthChange = (month: string) => {
        params.set("period", month);
        history.push(`${pathname}?${params.toString()}`);
        setReportMonth(month);
    };

    return (
        <>
            <div className={classes.header}>
                <h2 className={classes.title}>Monthly Progress Report:</h2>
                <div>
                <span className={classes.titlePeriod}>{` ${moment(reportMonth).format('MMMM YYYY')} `}</span>
                <Button
                    className={classes.button}
                    variant="outline"
                    leftIcon={<IconFilter size={16} />}
                    onClick={() => setDrawerOpened(true)}
                >
                    Select month
                </Button>
                </div>
            </div>
            <TabDetails title="Monthly Progress Report">
                <div className={classes.container}>
                    {loading ? (
                        <LoadingSpinner />
                    ) : reportData ? (
                        <iframe
                            src={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${store.auth.customerId}&fileId=${reportData.fileId}&versionId=${reportData.version || 0}`}
                            style={{ width: "100%", height: "100%" }}
                        ></iframe>
                    ) : (
                        <NoDataFound title={`No reports found for the selected month (${moment(reportMonth).format('MMMM YYYY')})`} />
                    )}
                </div>
            </TabDetails>
            <Drawer
                opened={drawerOpened}
                onClose={() => setDrawerOpened(false)}
                currentTab="monthly"
                handleTabDetails={handleMonthChange}
                dataFilters={allReports}
            />
        </>
    );
};

export default Monthly;
