import React from 'react'
import { Skeleton } from '@mantine/core';
import useStyles from './PropertyToolStyles';
import { useStore } from '../../../models/ProvideModel';
import { observer } from 'mobx-react-lite';

const PropertySkeletonLoad = () => {
  const store = useStore();
  const classes = useStyles();
  return (
    <div className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm}`}>
        <Skeleton height={15} radius="xs" />
    </div>
  )
}

export default observer(PropertySkeletonLoad)