import { makeStyles, Theme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) => ({
root: {
    // padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    // flex: 1,
    overflow: 'auto',
    height: '100%'
},
dropdownDiv: {
    display: 'flex',
    marginBottom: 16,
    width: '100%',
    padding: '0em 1em'
},
dropdownsContainer: {
    display: 'flex',
    gap: 16,
    width: '75%'
},
buttonsContainer: {
    display: 'flex',
    gap: 16,
    width: '25%',
    justifyContent: 'flex-end',
    alignItems: 'end',
},
dropdown: {
    padding: '0.5em',
    borderRadius: 0
},
saveButton: {
    padding: '0em 1em',
    background: '#005EFF',
    color: 'FFF',
    borderRadius: 0
},
cellChanged: {
    // background: '#fffbf1 !important',
    '& *': {  // Apply font size to all inner elements
      fontSize: '1em',
    },
},
plannedCell: {
    background: '#f0f7ed !important',
    // display: 'flex',
    '& *': {  // Apply font size to all inner elements
      fontSize: '1em',
    },
},
updatedCell: {
    background: 'pink !important',
    // display: 'flex',
    '& *': {  // Apply font size to all inner elements
      fontSize: '1em',
    },
},
title: {
    margin: '0.5em 0em',
    padding: '0em 1em'
}
}));

export default useStyles;