import React, { useState, useEffect } from "react";
import { TextInput, ActionIcon } from "@mantine/core";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { observer } from "mobx-react-lite";

interface PasswordCellEditorProps {
  initialValue: string;
  onChange: (value: string) => void;
}

const PasswordCellEditor: React.FC<PasswordCellEditorProps> = ({
  initialValue,
  onChange,
}) => {
  const [value, setValue] = useState(initialValue || "");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  return (
    <TextInput
      type={visible ? "text" : "password"}
      value={value}
      onChange={(e) => {
        setValue(e.currentTarget.value);
        onChange(e.currentTarget.value);
      }}
      rightSection={
        <ActionIcon onClick={() => setVisible((v) => !v)} style={{ cursor: "pointer" }}>
          {visible ? <IconEyeOff size={16} /> : <IconEye size={16} />}
        </ActionIcon>
      }
    />
  );
};

export default observer(PasswordCellEditor);
