import React, { useMemo } from "react";
import { Button, Group, Tooltip } from "@mantine/core";
import { MRT_ColumnDef } from "mantine-react-table";
import { Instance } from "mobx-state-tree";
import { LateStoreModel } from "../../../models/DataStore";
import { CommonApiEntities } from "../../../models/enums";
import StatusCell from "./customCells/StatusCell";
import DateCell from "./customCells/DateCell";
import moment from "moment";
import BuilderCell from "./customCells/BuilderCell";

export interface BaseProjectColumns {
    id: string,
    customerId?: string
    name: string,
    description?: string,
    status?: string,
    startDate?: string,
    endDate?: string,
    location?: string,
    builderId: string,
    projectImage?: string,
    syncCt?: string,
    syncUt?: string,
};

export interface ProjectDetails extends BaseProjectColumns {
    [K: string]: any;
};

export const useProjectTableColumnDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<ProjectDetails>[] = (store) => useMemo(() => [
    {
        accessorKey: "id",
        header: "Project ID",
        enableEditing: true,
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => {
            const isCreateMode = !row.original.id
            return {
                value: store.configManager.currentlyEditingRow?.id ?? row.original.id ?? "",
                disabled: !isCreateMode,
                onChange: (e) => {
                    if (isCreateMode) {
                        store.configManager.setEditingRow({ id: e.currentTarget.value });
                    }
                }
            };
        },

    },
    {
        accessorKey: "name",
        header: "Project Name",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.name ?? row.original.name ?? "",
            onChange: (e) => store.configManager.setEditingRow({ name: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "description",
        header: "Description",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.description ?? row.original.description ?? "",
            onChange: (e) => store.configManager.setEditingRow({ description: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "status",
        header: "Status",
        enableEditing: false,
        enableColumnFilterModes: false,
        Cell: ({ row }) => <StatusCell row={row} />
    },
    {
        accessorKey: "builderId",
        header: "Builder",
        enableColumnFilterModes: false,
        Cell: ({ row }) => {
                    const builder = store.configManager.builder.find(p => p.id === row.original.builderId);
                    return builder ? builder.name : row.original.builderId || "-";
                  },
                Edit: ({ row }) => <BuilderCell row={row}/>
    },
    {
        accessorKey: "startDate",
        header: "Start Date",
        enableColumnFilterModes: false,
        Cell: ({ row }) => {
            const dateStr = row.original.startDate;
            return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
          },
        Edit: ({ row }) => <DateCell row={row} field={'startDate'}/>
    },
    {
        accessorKey: "endDate",
        header: "End Date",
        enableColumnFilterModes: false,
        Cell: ({ row }) => {
            const dateStr = row.original.endDate;
            return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
          },
        Edit: ({ row }) => <DateCell row={row} field={'endDate'}/>
    },
    {
        accessorKey: "location",
        header: "Location",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.location ?? row.original.location ?? "",
            onChange: (e) => store.configManager.setEditingRow({ location: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "projectImage",
        header: "Project Image",
        enableColumnFilterModes: false,
        Cell: ({ cell }) =>
            cell.getValue() ? (
                <Tooltip label="Photo Preview">
                    <img src={cell.getValue() as string} alt="user" style={{ width: 32, height: 32 }} />
                </Tooltip>
            ) : (
                "-"
            ),
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.projectImage ?? row.original.projectImage ?? "",
            onChange: (e) => store.configManager.setEditingRow({ projectImage: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "actions",
        header: "Actions",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => (
            <Group spacing="xs">
                <Button
                    size="xs"
                    color="red"
                    variant="outline"
                    disabled={!row.original.id}
                    style={{ borderRadius: 0 }}
                    onClick={() => store.configManager.deleteEntityItem(CommonApiEntities.PROJECTS, row.original.id)}
                >
                    Delete
                </Button>
            </Group>
        ),
    },
], []);