import React, { useEffect, useRef, useState } from 'react';
import { DataSheetGrid, Column, DataSheetGridRef } from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { formatPercentage } from '../utils/utils';
import { Divider } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  gutterMainRoot: {
    display: 'flex !important',
    alignItems: 'flex-start !important',
    // padding: '10px',
    backgroundColor: '#fff !important',
    // borderBottom: '1px solid #ddd !important',
    height: '100% !important',
    boxSizing: 'border-box',
    width: '100% !important',
    padding: '0 !important'
  },
  sideA: {
    minWidth: '30px !important',
    fontWeight: 600,
    fontSize: '1em !important',
    color: '#005eff !important',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    borderRight: '1px solid #ddd !important',
    marginRight: '10px !important',
    height: '100% !important'
  },
  sideB: {
    flex: 1,
    display: 'flex !important',
    // flexDirection: 'column',
    // gap: '2px !important', // Space between rows
    height: '100% !important',
    '&:hover': {
      overflow: 'auto !important'
    }
  },
  labels: {
    color: '#909999 !important',
    // fontWeight: 600,
    fontSize: '0.65em !important',
  },
  data: {
    color: '#555',
    fontWeight: 400,
    fontSize: '0.8em',
    wordBreak: 'break-word'
  },
}))

interface IDataGridProps<T> {
  data: T[];
  columns: Column<T>[];
  onChange?: (updatedData: T[]) => void;
  reportType: string,
  currentMonth? : number
}

const DataGrid = <T,>({ data, columns, onChange, reportType, currentMonth }: IDataGridProps<T>) => {
  const classes = useStyles();
  const isDaily = ['daily'].includes(reportType);
  const [width, setWidth] = useState(window.innerWidth);
  const ref = useRef<DataSheetGridRef>(null)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //This is to scroll to current day of current month.
    if(reportType == "daily") {
      const today = moment()  //Get today's day in the month
      const day = today.date();
      const month = today.month()+1;
      if(currentMonth === month) { ref.current?.setActiveCell( { col: day + 2, row: 0 }) }
    }
  }, [])

  const progressColor = (value: any) => value < 50 ? '#ff4d4d' : value >= 50 && value < 80 ? '#d19d02' : '#4caf50';

  const UnitActivityCell = ({ rowData }) => {
    const classes = useStyles();
    const monthCumulative = (rowData['monthsTarget'] && rowData['monthsTarget'] > 0) ? (Number.parseFloat(rowData['totalAchievedForMonthSoFar']) / Number.parseFloat(rowData['monthsTarget']) * 100 ).toFixed(2) : 0
    const yearCumulative = (rowData['plannedTotalQty'] &&  rowData['plannedTotalQty'] > 0) ? (Number.parseFloat(rowData['totalAchievedForYearSoFar']) / Number.parseFloat(rowData['plannedTotalQty']) * 100 ).toFixed(2) : 0
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2px' }}>
        <div>
          {(rowData['unitTypeId'] === 'block') ?
            <span className={classes.data} style={{ fontWeight: 600, letterSpacing: 0.7, color: '#000',fontSize: '0.8em' }}>{rowData['blockName'] || '-'}</span> :
            <span className={classes.data} style={{ fontWeight: 600, letterSpacing: 0.7, color: '#000',fontSize: '0.8em' }}>{rowData['unitTitle'] || '-'}</span>
          }
        </div>
        <Divider style={{ width: '15%', background: '#005eff', height: '0.075em' }} />
        <div>
          <span className={`${classes.data}`} style={{ fontWeight: 600, letterSpacing: 0.7, color: '#005eff', fontSize: '1em' }}>{rowData['activity'] || '-'}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap' }}>
        <div style={{ width: '25%', display: 'flex', flexDirection: 'column', marginBottom: '0.25em', marginTop: '0.25em' }}>
            <span className={classes.labels}>Planned start:</span>{' '}
            <span className={classes.data}>
              {rowData['plannedStart'] || '-'}{' '}
            </span>
          </div>
          <div style={{ width: '25%', display: 'flex', flexDirection: 'column', marginBottom: '0.25em', marginTop: '0.25em' }}>
            <span className={classes.labels}>Planned end:</span>{' '}
            <span className={classes.data}>
              {rowData['plannedEnd'] || '-'}{' '}
            </span>
          </div>
          <div style={{ width: '25%', display: 'flex', flexDirection: 'column', marginBottom: '0.25em', marginTop: '0.25em' }}>
            <span className={classes.labels}>Total QTY:</span>{' '}
            <span className={classes.data}>
              {rowData['plannedTotalQty'] || '-'}{' '}
              {rowData['plannedTotalQty'] ? rowData['unitOfMeasure'] : ''}
            </span>
          </div>
          {isDaily ? <div style={{ width: '25%', display: 'flex', flexDirection: 'column', marginBottom: '0.25em' }}>
          <span className={classes.labels}>Month's target:</span>{' '}
          <span className={classes.data}>
            {rowData['monthsTarget'] || '-'}{' '}
            {rowData['monthsTarget'] ? rowData['unitOfMeasure'] : ''}
          </span>
        </div> : null}
        {(rowData['unitTypeId'] !== 'block') && 
        <div style={{ width: '25%', display: 'flex', flexDirection: 'column', marginBottom: '0.25em' }}>
            <span className={classes.labels}>Block:</span>{' '}
            <span className={classes.data}>{rowData['blockName'] || '-'}</span>
          </div>
        }
        {(rowData['unitTypeId'] !== 'block') &&
            <div style={{ width: '25%', display: 'flex', flexDirection: 'column', marginBottom: '0.25em' }}>
              <span className={classes.labels}>Floor:</span>{' '}
              <span className={classes.data}>{rowData['floorLabel'] || '-'}</span>
            </div>
          }
          {isDaily ? <div style={{ width: '50%', display: 'flex', flexDirection: 'column', marginBottom: '0.25em' }}>
          <span className={classes.labels}>Cumulative Achvd. (for month):</span>{' '}
          <span className={classes.data}>
            {rowData['monthsTarget'] ? rowData['totalAchievedForMonthSoFar'] + ' ' + (rowData['unitOfMeasure'] || '') : '-'}{' '}
            <span style={{color: progressColor(monthCumulative)}}>{(rowData['monthsTarget'] && rowData['monthsTarget'] > 0) ? 
            // `(${monthCumulative}%)` 
            `(${formatPercentage(rowData['totalAchievedForMonthSoFar'], rowData['monthsTarget'], true)}%)` 
            : '-'}</span>
          </span>
        </div> : null}
        {!isDaily ? <div style={{ width: '50%', display: 'flex', flexDirection: 'column', marginBottom: '0.25em' }}>
          <span className={classes.labels}>Cumulative Achvd. (for year):</span>{' '}
          <span className={classes.data}>
            {rowData['totalAchievedForYearSoFar'] + ' ' + (rowData['unitOfMeasure'] || '')}{' '}
            <span style={{color: progressColor(yearCumulative)}}>{(rowData['plannedTotalQty'] && rowData['plannedTotalQty'] > 0) ? 
            // `(${yearCumulative}%)`
            `(${formatPercentage(rowData['totalAchievedForYearSoFar'], rowData['plannedTotalQty'], true)}%)`
             : '-'}</span>
          </span>
        </div> : null}
        </div>
      </div>
    )
  }

  return (
    <DataSheetGrid
      key={width}
      ref={ref}
      value={data}
      onChange={onChange}
      columns={columns}
      // height={450}
      style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}
      height={1085}
      rowHeight={120}
      lockRows
      headerRowHeight={35}
      gutterColumn={{
        basis: 400,
        component: ({ rowData, rowIndex }) => {
          return (
            <div className={classes.gutterMainRoot}>
              <div className={classes.sideA}>{rowIndex + 1}</div>
              <div className={classes.sideB}>
                <UnitActivityCell rowData={rowData} />
              </div>
            </div>
          )
        },
        // title: () => <div style={{display: 'flex'}}>Unit Activity details</div>,
        title: 'Unit Activity details'
      }}
    />
  );
};

export default DataGrid;
