import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router"; // ✅ Use 'react-router'
import { Tabs } from "@mantine/core";
import Daily from "./tabs/daily/Daily";
import Weekly from "./tabs/weekly/Weekly";
import Monthly from "./tabs/monthly/Monthly";
import { observer } from "mobx-react-lite";
import moment from "moment";

const DPRReportsPage = () => {
    const location = useLocation();
    const history = useHistory();
    // Extract tab from pathname
    const segments = location.pathname.split("/");
    let currentTab = segments[2];

    // Redirect to "/progress-report/daily" if no specific tab is in the URL
    useEffect(() => {
        if (!["daily", "weekly", "monthly"].includes(currentTab)) {
            const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
            history.replace(`/progress-report/daily?period=${yesterday}`); // ✅ Redirect to daily tab if no valid tab found
            currentTab = "daily"; //✅ Default to "daily" if no tab is specified
        }
    }, [currentTab, history]);

    const handleTabChange = (tab: string) => {
        history.push(`/progress-report/${tab}`); // ✅ Update URL on tab change
    };

    return (
        <Tabs value={currentTab} onTabChange={handleTabChange} style={{ width: '100%' }}>
            <Tabs.List>
                <Tabs.Tab value="monthly">Monthly</Tabs.Tab>
                <Tabs.Tab value="weekly">Weekly</Tabs.Tab>
                <Tabs.Tab value="daily">Daily</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="daily" style={{ height: '100%' }}>
                {currentTab === "daily" && <Daily />}
            </Tabs.Panel>
            <Tabs.Panel value="weekly" style={{ height: '100%' }}>
                {currentTab === "weekly" && <Weekly />}
            </Tabs.Panel>
            <Tabs.Panel value="monthly" style={{ height: '100%' }}>
                {currentTab === "monthly" && <Monthly />}
            </Tabs.Panel>
        </Tabs>
    );
};

export default observer(DPRReportsPage);
