import { Select, Text } from '@mantine/core'
import React, { forwardRef } from 'react'
import { useStore } from '../../../../models/ProvideModel'
import { observer } from 'mobx-react-lite';

interface SelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string;
    label: string;
    group?: string;
  }

const PropertyCell = ({ row }) => {
    const store = useStore();
    return (
        <Select
          value={store.configManager.currentlyEditingRow?.propertyId ?? row.original.propertyId}
          onChange={(val) => {
            store.configManager.setEditingRow({ propertyId: val });
            row._valuesCache["propertyId"] = val
        }}
          data={store.configManager.propertyOptionsWithProject}
          searchable
          clearable
          withinPortal
          maxDropdownHeight={400}
          size="xs"
          nothingFound="No matching property"
          disabled={row.original.propertyId}
          filter={(value, item) =>
            item.label?.toLowerCase().includes(value.toLowerCase()) ||
            (item.group?.toLowerCase().includes(value.toLowerCase()) ?? false) ||
            item.value.toLowerCase().includes(value.toLowerCase())
        }
          itemComponent={forwardRef<HTMLDivElement, SelectItemProps>(({ value, label, group, ...others }, ref) => (
            <div ref={ref} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text weight={500}>{label}</Text>
              {/* <Text size="xs" color="gray">{group}</Text> */}
            </div>
          ))}
        />
      )
}

export default observer(PropertyCell);