import React, { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../models/ProvideModel";
import MantineTable from '../../../components/requests/MantineTable';
import { CommonApiEntities } from "../../../models/enums";
import LoadingTowers from "../../../components/LoadingTowers";
import { useProjectTableColumnDef } from "./gridColumns";
import { useTableOptions } from "./tableOptions";
import ProjectToolbarComponent from "./ProjectsToolbar";

const Projects = () => {
    const store = useStore();
    const columns = useProjectTableColumnDef(store);
    const tableOptions = useTableOptions(store);
    const ToolbarComponent = useMemo(() => ({ table }) => <ProjectToolbarComponent table={table} />, []);

    useEffect(() => {
        let isMounted = true;    
        const loadUsers = async () => {
            try {
                await store.configManager.fetchEntityData(CommonApiEntities.PROJECTS);
            } catch (error) {
                if (isMounted) { console.error('Failed to load projects:', error); }
            }
        };
        loadUsers();
        return () => {
            isMounted = false;
        };
    }, [store.configManager.currentCustomer]);

    const useFilterListeners = () => { };

    const getFilteredRowCount = useCallback((rowCount: number) => {
        store.configManager.setFilterRowsCount(rowCount);
      }, []);

    return (
        <div style={{ display: "flex", height: "100%", width: "100%", flexGrow: 1, flexShrink: 1 }}>
            {store.configManager.loading ?
                <LoadingTowers title={"Fetching projects"} /> :
                <MantineTable
                    data={store.configManager.projects}
                    columns={columns}
                    setRowCount={getFilteredRowCount}
                    rowCallback={() => { }}
                    ToolbarComponent={ToolbarComponent}
                    useFilterListeners={useFilterListeners}
                    tableOptions={tableOptions}
                />
            }
        </div>
    );
};

export default observer(Projects);
