import React, { memo, useEffect, useRef, useMemo, Dispatch, ReducerAction } from 'react';
import { Anchor } from '@mantine/core';

export interface IExpressionInputProps {
  dispatch: Dispatch<ReducerAction<any>>;
  state: any;
  label: string;
  type: string;
  field: string;
  params: { [K: string]: any; };
};

const HyperlinkInput = ({ label, params: { hyperlinkUrl } }: IExpressionInputProps) => (
  <Anchor href={hyperlinkUrl} target="_blank">
    {label || hyperlinkUrl}
  </Anchor>
);
export default memo(HyperlinkInput);
