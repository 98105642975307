import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router';
import { Button } from "@mantine/core";
import TabDetails from "../tabDetails/TabDetails";
import moment from "moment";
import { getDPRReport } from "../../../../../api/transactionServer";
import Drawer from "../../drawer/Drawer";
import { useStore } from "../../../../../models/ProvideModel";
import { ProgressReportFrequencies } from "../../../../../models/enums";
import { IFile } from "../../../../../containers/ticketPopup/tabItems/attachments/Attachment";
import { IconFilter } from "@tabler/icons-react";
import useStyles from './DailyStyles'
import LoadingSpinner from "../../../../../components/loadingSkelaton/LoadingSpinner";
import NoDataFound from "../../../../fbtReports/NoDataFound";

// ✅ Function to check valid date format (YYYY-MM-DD)
const isValidDate = (dateString: string) => {
    return moment(dateString, "YYYY-MM-DD", true).isValid();
};

function extractFileData(data: { [K: string]: any; }): IFile | null {
    const { fileId, fileName, version } = data;
    if (!fileId) { return null; }
    return {
        fileId,
        fileName,
        version,
        fileType: "application/pdf",
    } as IFile;
}

const Daily = () => {
    const { classes } = useStyles();
    const { search, pathname } = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(search);
    const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

    // ✅ Validate URL param and fallback to yesterday if invalid
    let initialPeriod = params.get("period");
    if (!initialPeriod || !isValidDate(initialPeriod)) {
        initialPeriod = yesterday;
        params.set("period", yesterday);
        history.replace(`${pathname}?${params.toString()}`); // 🔹 Use replace to avoid extra history entries
    }

    const [reportData, setReportData] = useState<IFile | null>(null);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [reportDate, setReportDate] = useState<string>(initialPeriod);
    const store = useStore();
    const [loading, setLoading] = useState(false);
    const [allReports, setAllReports] = useState([]);

    // ✅ Fetch DPR report when period changes
    useEffect(() => {
        async function fetchReport() {
            if (!store.projectInfo.currentProject) return;
            
            try {
                setLoading(true);

                const targetPeriodForReportDateMonth = moment(reportDate).year() +'-'+ (moment(reportDate).month() + 1).toString().padStart(2, '0')
                //Fetch all dates for the month which have reports generated
                const { data: allReportsForMonth } = await getDPRReport({
                    projectId: store.projectInfo.currentProject.id,
                    targetPeriod: targetPeriodForReportDateMonth,
                    frequency: ProgressReportFrequencies.DAY
                });
                if(allReportsForMonth) {
                    setAllReports(allReportsForMonth.map(rep => rep.targetPeriod))
                    setReportData(allReportsForMonth.find(rep => rep.targetPeriod === reportDate))
                }

                // setReportData(Array.isArray(data) && data.length ? extractFileData(data[0]!) : data);
            } catch (error) {
                console.error("Error fetching DPR report:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchReport();
    }, [reportDate]); // ✅ Only fetch when reportDate changes

    // ✅ Function to handle period change from Drawer
    const handleDayChange = (date: string) => {
        params.set("period", date);
        history.push(`${pathname}?${params.toString()}`); // ✅ Updates URL
        setReportDate(date);
    };

    return (
        <>
            <div className={classes.header}>
                <h2 className={classes.title}>Daily Progress Report:</h2>
                <div>
                <span className={classes.titlePeriod}>{` ${moment(reportDate).format('DD-MMMM-YYYY')} `}</span>
                <Button
                    className={classes.button}
                    variant="outline"
                    leftIcon={<IconFilter size={16} />}
                    onClick={() => setDrawerOpened(true)} // ✅ Open Drawer
                >
                    Select date
                </Button>
                </div>
            </div>
            <TabDetails title="Daily Progress Report">
                {/* Report Content */}
                <div className={classes.container}>
                    {loading ?
                        (
                            <LoadingSpinner />
                        ) :
                        reportData ?
                            (
                                <div style={{ display: "flex", width: "100%", height: "100%" }}>
                                    <iframe src={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${store.auth.customerId}&fileId=${reportData.fileId}&versionId=${reportData.version || 0}`}
                                        style={{ width: "100%", height: "100%" }}
                                    >
                                    </iframe>
                                </div>
                            )
                            :
                            (
                                <div style={{ display: "flex", placeContent: "center", alignSelf: "center", width: "100%" }}>
                                    <NoDataFound title={`No reports found for the selected date (${moment(reportDate).format('DD-MMM-YYYY')}).`} />
                                </div>
                            )}
                </div>
            </TabDetails>
            <Drawer
                opened={drawerOpened}
                onClose={() => setDrawerOpened(false)}
                currentTab="daily"
                handleTabDetails={handleDayChange}
                dataFilters={allReports}
            />
        </>
    );
};

export default Daily;
