import { createStyles } from "@mantine/core";

export default createStyles((theme) => ({
  drawer: {
    backgroundColor: theme.colors.gray[0],
  },
  title: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
  },
  content: {
    padding: "1rem", // ✅ Added this property to avoid the error
  },
  button: {
    borderRadius: 0,
    borderColor: '#005eff'
  }
}));
