import React from 'react'
import { useStore } from '../../../../models/ProvideModel';
import { Switch } from '@mantine/core';
import { observer } from 'mobx-react-lite';

const NotifyComplete = ({ row }) => {
        const store = useStore();
          const value = store.configManager.currentlyEditingRow?.notificationOnComplete ?? row.original.notificationOnComplete;
      
          return (
            <Switch
              size="lg"
              checked={Boolean(value)}
              onLabel="Yes"
              offLabel="No"
              style={{ cursor: 'pointer' }}
              onChange={(e) =>
                store.configManager.setEditingRow({ notificationOnComplete: e.currentTarget.checked })
              }
            />
          );
        }

export default observer(NotifyComplete);