import React from 'react'
import { useStore } from '../../../../models/ProvideModel';

const FloorDrawings = ({ row }) => {
    const store = useStore();
    const rawJson = store.configManager.currentlyEditingRow?.floorDrawings || row.original.floorDrawings;

    return (
      <textarea
        style={{ width: "100%", minHeight: "150px", fontFamily: "monospace", fontSize: "0.85em" }}
        defaultValue={JSON.stringify(rawJson, null, 2)}
        onChange={(e) => {
          try {
            const parsed = JSON.parse(e.target.value);
            store.configManager.setEditingRow({ floorDrawings: parsed });
          } catch (err) {
            // Ignore invalid JSON while typing
          }
        }}
      />
    );
  }

export default FloorDrawings