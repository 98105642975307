import React from 'react'
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../models/ProvideModel";
import { Select } from "@mantine/core";

const GenderCell = ({ row }) => {
        const store = useStore();
        const isCreateMode = !row.original.id;
        const currentValue = store.configManager.currentlyEditingRow?.gender ?? row.original.gender;
    
        return (
          <Select
            size="xs"
            value={currentValue}
            data={[
              { value: "M", label: "Male" },
              { value: "F", label: "Female" },
              { value: "O", label: "Others" },
            ]}
            onChange={(val: string) => {
              store.configManager.setEditingRow({ gender: val });
              // For create mode: keep row updated for values in onCreatingRowSave
              if (isCreateMode) {
                row._valuesCache["gender"] = val
              }
            }}
          />
        );
      }

export default observer(GenderCell);
