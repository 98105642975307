import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { regenerateChecklistReport } from '../../api/transactionServer';
import { useStore } from '../../models/ProvideModel';
import { IconReload } from '@tabler/icons-react';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';

const RegenerateReport = ({ row: { original }, column: { reload } }: { row: { original: { [K: string]: any; }; }; column: { reload: () => any; }; }) => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(() => {
    setLoading(true);
    regenerateChecklistReport({ customerId: store.auth.customerId, projectId: store.projectInfo.currentProject!.id, propertyId: original["property_id"], activityTypeId: original['activity_type_id'], untiActivityId: original["unit_activity_id"], fromNode: original["from_node"], toNode: original["to_node"] })
      .then(() => { setLoading(false); return new Promise((rs, _) => setTimeout(() => { rs(null); }, 2000)); })
      .then(() => { reload(); })
      .catch(err => { console.error(`Error response: ${err}`); setLoading(false); })
  }, []);
  return (
    <Flex gap="md">
      <Tooltip label={loading ? "Regenerating..." : "Regenerate This Report"}>
        <ActionIcon onClick={handleClick} color="indigo" disabled={loading}>
          <IconReload style={{ animation: loading ? 'rotate 1.5s ease-in-out infinite' : 'none' }} />
        </ActionIcon>
      </Tooltip>
      <style>
        {`
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </Flex>
  );
};

export default observer(RegenerateReport);
