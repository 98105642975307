import React, { useMemo } from "react";
import { Button, Group, Switch, Tooltip } from "@mantine/core";
import { MRT_ColumnDef } from "mantine-react-table";
import { Instance } from "mobx-state-tree";
import { LateStoreModel } from "../../../models/DataStore";
import { CommonApiEntities } from "../../../models/enums";
import StatusCell from "./customCells/StatusCell";
import moment from "moment";
import FloorDrawings from "./customCells/FloorDrawings";
import NotifyComplete from "./customCells/NotifyComplete";
import ProjectCell from "./customCells/ProjectCell";

export interface BasePropertyColumns {
    id: string,
    customerId?: string
    name: string,
    address?: string,
    status?: string,
    appStatus?: string,
    projectId: string,
    floors?: string,
    floorDrawings?: string,
    notificationOnComplete?: string,
    propertyImage?: string,
    syncCt?: string,
    syncUt?: string,
};

export interface PropertyDetails extends BasePropertyColumns {
    [K: string]: any;
};


export const usePropertyTableColumnDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<PropertyDetails>[] = (store) => useMemo(() => [
    {
        accessorKey: "id",
        header: "Property ID",
        enableEditing: true,
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => {
            const isCreateMode = !row.original.id
            return {
                value: store.configManager.currentlyEditingRow?.id ?? row.original.id ?? "",
                disabled: !isCreateMode,
                onChange: (e) => {
                    if (isCreateMode) {
                        store.configManager.setEditingRow({ id: e.currentTarget.value });
                    }
                }
            };
        },

    },
    {
        accessorKey: "name",
        header: "Property Name",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.name ?? row.original.name ?? "",
            onChange: (e) => store.configManager.setEditingRow({ name: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "address",
        header: "Address",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.address ?? row.original.address ?? "",
            onChange: (e) => store.configManager.setEditingRow({ address: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "status",
        header: "Status",
        enableEditing: false,
        enableColumnFilterModes: false,
        Cell: ({ row }) => <StatusCell row={row} />
    },
    {
        accessorKey: "projectId",
        header: "Project",
        enableColumnFilterModes: false,
        Cell: ({ row }) => {
            const project = store.configManager.projects.find(p => p.id === row.original.projectId);
            return project ? project.name : row.original.projectId || "-";
          },
        Edit: ({ row }) => <ProjectCell row={row}/>
    },
    {
        accessorKey: "propertyImage",
        header: "Property Image",
        enableColumnFilterModes: false,
        Cell: ({ cell }) =>
            cell.getValue() ? (
                <Tooltip label="Photo Preview">
                    <img src={cell.getValue() as string} alt="user" style={{ width: 32, height: 32 }} />
                </Tooltip>
            ) : (
                "-"
            ),
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.propertyImage ?? row.original.propertyImage ?? "",
            onChange: (e) => store.configManager.setEditingRow({ propertyImage: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "floors",
        header: "Floors",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.floors ?? row.original.floors ?? 0,
            onChange: (e) => store.configManager.setEditingRow({ floors: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "floorDrawings",
        header: "Floor Drawings",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => {
            try {
              const value = cell.getValue();
              return (
                <pre style={{ whiteSpace: "pre-wrap", fontSize: "0.75em" }}>
                  {JSON.stringify(value, null, 2)}
                </pre>
              );
            } catch (e) {
              return <span style={{ color: 'red' }}>Invalid JSON</span>;
            }
          },
        Edit: ({ row }) => <FloorDrawings row={row}/>
    },
    {
        accessorKey: "notificationOnComplete",
        header: "Notify On Complete",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return (
            <Switch
              size="lg"
              readOnly
              checked={Boolean(value)}
              onLabel="Yes"
              offLabel="No"
            />
          );
        },
        Edit: ({ row }) => <NotifyComplete row={row}/>
      },
    {
        accessorKey: "syncUt",
        header: "Updated On",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => {
            const dateStr = row.original.syncUt;
            return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
        },
    },
    {
        accessorKey: "syncCt",
        header: "Created On",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => {
            const dateStr = row.original.syncCt;
            return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
        },
    },
    {
        accessorKey: "actions",
        header: "Actions",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => (
            <Group spacing="xs">
                <Button
                    size="xs"
                    color="red"
                    variant="outline"
                    disabled={!row.original.id}
                    style={{ borderRadius: 0 }}
                    onClick={() => store.configManager.deleteEntityItem(CommonApiEntities.PROPERTIES, row.original.id)}
                >
                    Delete
                </Button>
            </Group>
        ),
    },
], []);