import React from 'react'
import { useStore } from '../../../../models/ProvideModel';
import { Select } from '@mantine/core';
import { observer } from 'mobx-react-lite';

const ProjectCell = ({ row }) => {
    const store = useStore();
    const projectOptions = (store.configManager.projects ?? []).map(project => ({
      value: project.id,
      label: project.name,
    }));

    return (
      <Select
        placeholder="Select project"
        data={projectOptions}
        searchable
        disabled={row.original.projectId}
        value={
          store.configManager.currentlyEditingRow?.projectId ??
          row.original.projectId
        }
        onChange={(val) => {
          store.configManager.setEditingRow({ projectId: val });
          row._valuesCache["projectId"] = val
        }}
      />
    );
  }

export default observer(ProjectCell);