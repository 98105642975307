import { createStyles } from "@mantine/core";

export default createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: '100%'
  },
  content: {
    // display: 'flex',
    // flexDirection: 'column',
    // flexGrow: 1,
    // overflowY: "auto",
    height: '100%',
    // padding: "1rem",
    // minHeight: 0
  },
}));
