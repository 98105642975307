import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router';
import { Button } from "@mantine/core";
import TabDetails from "../tabDetails/TabDetails";
import moment from "moment";
import { getDPRReport } from "../../../../../api/transactionServer";
import Drawer from "../../drawer/Drawer";
import { useStore } from "../../../../../models/ProvideModel";
import { ProgressReportFrequencies } from "../../../../../models/enums";
import { IconFilter } from "@tabler/icons-react";
import useStyles from './WeeklyStyles';
import LoadingSpinner from "../../../../../components/loadingSkelaton/LoadingSpinner";
import NoDataFound from "../../../../fbtReports/NoDataFound";
import { IFile } from "../../../../../containers/ticketPopup/tabItems/attachments/Attachment";

// ✅ Validate weekly format (YYYY-WW)
const isValidWeek = (weekString: string) => {
    return moment(weekString, "YYYY-[W]WW", true).isValid();
};

const Weekly = () => {
    const { classes } = useStyles();
    const { search, pathname } = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(search);
    
    // Default to last week's data
    const lastWeek = moment().subtract(1, "week").format("YYYY-[W]WW");

    // ✅ Validate `period` param
    let initialPeriod = params.get("period");
    if (!initialPeriod || !isValidWeek(initialPeriod)) {
        initialPeriod = lastWeek;
        params.set("period", lastWeek);
        history.replace(`${pathname}?${params.toString()}`);
    }

    const [reportData, setReportData] = useState<IFile | null>(null);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [reportWeek, setReportWeek] = useState<string>(initialPeriod);
    const store = useStore();
    const [loading, setLoading] = useState(false);
    const [allReports, setAllReports] = useState([]);

    const startDate = moment(reportWeek).startOf("isoWeek").format("DD-MMM-YYYY");
    const endDate = moment(reportWeek).endOf("isoWeek").format("DD-MMM-YYYY");

    useEffect(() => {
        async function fetchReport() {
            if (!store.projectInfo.currentProject) return;

            try {
                setLoading(true);

                const targetPeriodForReportWeekYear = moment(reportWeek).year().toString()
                //Fetch all weeks for the month which have reports generated
                const { data: allWeeklyReportsForYear } = await getDPRReport({
                    projectId: store.projectInfo.currentProject.id,
                    targetPeriod: targetPeriodForReportWeekYear,
                    frequency: ProgressReportFrequencies.WEEK
                });
                if (allWeeklyReportsForYear) {
                    setAllReports(allWeeklyReportsForYear.map(rep => rep.targetPeriod));
                    setReportData(allWeeklyReportsForYear.find(rep => rep.targetPeriod === reportWeek))
                }

                // setReportData(data.length ? data[0] : null);
            } catch (error) {
                console.error("Error fetching weekly report:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchReport();
    }, [reportWeek]);

    const handleWeekChange = (week: string) => {
        params.set("period", week);
        history.push(`${pathname}?${params.toString()}`);
        setReportWeek(week);
    };

    return (
        <>
            <div className={classes.header}>
                <h2 className={classes.title}>Weekly Progress Report:</h2>
                <div>
                <span className={classes.titlePeriod}>{`Week ${moment(reportWeek).format('WW-YYYY')} ( ${startDate} - ${endDate} )`}</span>
                <Button
                    className={classes.button}
                    variant="outline"
                    leftIcon={<IconFilter size={16} />}
                    onClick={() => setDrawerOpened(true)}
                >
                    Select week
                </Button>
                </div>
            </div>
            <TabDetails title="Weekly Progress Report">
                <div className={classes.container}>
                    {loading ? (
                        <LoadingSpinner />
                    ) : reportData ? (
                        <iframe
                            src={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${store.auth.customerId}&fileId=${reportData.fileId}&versionId=${reportData.version || 0}`}
                            style={{ width: "100%", height: "100%" }}
                        ></iframe>
                    ) : (
                        <NoDataFound title={`No reports found for the selected week (${reportWeek})`} />
                    )}
                </div>
            </TabDetails>
            <Drawer
                opened={drawerOpened}
                onClose={() => setDrawerOpened(false)}
                currentTab="weekly"
                handleTabDetails={handleWeekChange}
                dataFilters={allReports}
            />
        </>
    );
};

export default Weekly;
