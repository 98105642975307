import React from 'react'
import { useStore } from '../../../../models/ProvideModel';
import { DateTimePicker } from '@mantine/dates';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

const DateCell = ({ row, field }) => {
    const store = useStore();
    const currentVal = store.configManager.currentlyEditingRow && store.configManager.currentlyEditingRow[field] || row.original[field];

    return (
      <DateTimePicker
        translate={''}
        value={currentVal ? new Date(currentVal) : null}
        onChange={(date) => {
          const formatted = date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : null;
          store.configManager.setEditingRow({ [field]: formatted });
          row._valuesCache[field] = formatted
        }}
        valueFormat="YYYY-MM-DD HH:mm:ss"
        clearable
        size="xs"
        style={{ minWidth: "140px" }}
      />
    );
  }

export default observer(DateCell);