import React from "react";
import useStyles from "./TabDetailsStyles";

interface TabDetailsProps {
  title: string;
  children: React.ReactNode;
}

const TabDetails: React.FC<TabDetailsProps> = ({ title, children }) => {
  const { classes } = useStyles();

  return (
    <div 
    style={{ height: 'calc(100% - 100px)' }}
    // className={classes.container}
    >
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default TabDetails;
