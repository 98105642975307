import React from "react";
import { Drawer as MantineDrawer, Button, Group, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";
import useStyles from "./DrawerStyles";
import { useHistory, useLocation } from 'react-router';
import { IconCalendar } from "@tabler/icons-react";

const isFutureDate = (date) => moment(date).isAfter(moment(), "day");

const Drawer = ({ opened, onClose, currentTab, handleTabDetails, dataFilters }) => {
    const { classes } = useStyles();
    const { search, pathname } = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(search);
    const period = params.get("period") || undefined;

    // ✅ Generate list of dates for the current month
    const generateMonthDates = () => {
        const startOfMonth = moment().startOf("month");
        const endOfMonth = moment().endOf("month");
        let dates: any = [];
        for (let day = startOfMonth; day.isBefore(endOfMonth); day.add(1, "day")) {
            dates.push(day.format("YYYY-MM-DD"));
        }
        return dates;
    };

    // ✅ Generate last 8 weeks with start & end dates
    const generateLast8Weeks = () => {
        let weeks: any = [];
        for (let i = 8; i > 0; i--) {
            const week = moment().subtract(i, "weeks");
            const weekYear = week.isoWeekYear();
            const weekNumber = week.isoWeek();
            const startDate = week.startOf("isoWeek").format("DD-MMM-YYYY");
            const endDate = week.endOf("isoWeek").format("DD-MMM-YYYY");
            weeks.push({ week: `${weekYear}-W${String(weekNumber).padStart(2, "0")}`, startDate, endDate, weekNumber, weekYear });
        }
        return weeks;
    };

    // ✅ Generate last 6 months
    const generateLast6Months = () => {
        let months: any = [];
        for (let i = 6; i > 0; i--) {
            months.push(moment().subtract(i, "months").format("YYYY-MM"));
        }
        return months;
    };

    // ✅ Function to update URL with new period filter
    const updatePeriodFilter = (newDate) => {
        params.set("period", newDate);
        history.push(`${pathname}?${params.toString()}`);
    };

    const DrawerTitle = () => {
        const message = currentTab === 'daily' ? `a date` : currentTab === 'weekly' ? `a week` : `a month`;
        return <span className={classes.title}>{`Choose ${message}:`}</span>;
    };

    return (
        <MantineDrawer
            opened={opened}
            onClose={onClose}
            title={<DrawerTitle />}
            position="right"
            padding="md"
            size="xs"
        >
            {/* ✅ Render dates only if currentTab is 'daily' */}
            {currentTab === "daily" && (
                <div>
                    <Group spacing="sm">
                        {generateMonthDates().map((date) => (
                            <Button
                                key={date}
                                className={classes.button}
                                variant={date === period ? "filled" : "outline"}
                                onClick={() => {
                                    handleTabDetails(date);
                                    updatePeriodFilter(date);
                                    onClose();
                                }}
                                disabled={isFutureDate(date) || !dataFilters.includes(date)}
                            >
                                {moment(date).format("DD-MMM-YYYY")}
                            </Button>
                        ))}
                    </Group>
                    <div style={{ padding: '1em 0.5em', bottom: 0, position: 'sticky', background: '#FFF', boxShadow: `0px -5px 20px 0px rgba(209,209,209,1)`, marginTop: '1em' }}>
                        <div style={{ fontSize: '0.9em', fontWeight: 'bold', marginBottom: '0.5em', color: '#585858' }}>Custom date:</div>
                        <DatePickerInput
                            value={moment(period).toDate()}
                            onChange={(val) => {
                                if (val) {
                                    handleTabDetails(moment(val).format("YYYY-MM-DD"));
                                    updatePeriodFilter(moment(val).format("YYYY-MM-DD"));
                                    onClose();
                                }
                            }}
                            placeholder="Select a date"
                            maxDate={moment().utc().toDate()}
                            rightSection={<IconCalendar height={'1em'} width={'1em'} />}
                        />
                    </div>
                </div>
            )}
            {/* ✅ Weekly tab with week number + date range */}
            {currentTab === "weekly" && (
                <div>
                    <Group spacing="sm">
                        {generateLast8Weeks().map(({ week, startDate, endDate, weekNumber, weekYear }) => (
                            <div key={week} style={{ textAlign: "center", width: "100%", border: `0.01em solid #005eff`, padding: '0.75em' }}>
                                <Button
                                    className={classes.button}
                                    variant={week === period ? "filled" : "outline"}
                                    onClick={() => {
                                        handleTabDetails(week);
                                        updatePeriodFilter(week);
                                        onClose();
                                    }}
                                    disabled={!dataFilters.includes(week)}
                                >
                                    <Text size="xs">Week {String(weekNumber).padStart(2, "0")}-{weekYear}</Text>
                                </Button>
                                <Text size="xs" color="dimmed" style={{ marginTop: '0.5em' }}>{startDate} - {endDate}</Text>
                            </div>
                        ))}
                    </Group>
                </div>
            )}
            {/* ✅ Monthly tab with months */}
            {currentTab === "monthly" && (
                <div>
                    <Group spacing="sm">
                        {generateLast6Months().map((month) => (
                            <Button
                                key={month}
                                className={classes.button}
                                variant={month === period ? "filled" : "outline"}
                                onClick={() => {
                                    handleTabDetails(month);
                                    updatePeriodFilter(month);
                                    onClose();
                                }}
                                disabled={!dataFilters.includes(month)}
                            >
                                {moment(month).format("MMM YYYY")}
                            </Button>
                        ))}
                    </Group>
                </div>
            )}
        </MantineDrawer>
    );
};

export default Drawer;
