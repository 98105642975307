import React from 'react'
import { useStore } from '../../../../models/ProvideModel';
import { Select } from '@mantine/core';
import { observer } from 'mobx-react-lite';

const BuilderCell = ({ row }) => {
    const store = useStore();
    const builderOptions = (store.configManager.builder ?? []).map(builder => ({
      value: builder.id,
      label: builder.name,
    }));

    return (
      <Select
        placeholder="Select builder"
        data={builderOptions}
        searchable
        disabled={row.original.builderId}
        value={
          store.configManager.currentlyEditingRow?.builderId ??
          row.original.builderId
        }
        onChange={(val) => {
          store.configManager.setEditingRow({ builderId: val });
          row._valuesCache["builderId"] = val
        }}
      />
    );
  }

export default observer(BuilderCell);