import { types, SnapshotOut, Instance, flow, getRoot, resolveIdentifier } from 'mobx-state-tree';
import { getProperties, getProjects } from '../api/Auth';
import { LateStoreModel } from './DataStore';
import { putAsync } from 'csp-with-ts/lib';
import { commonFetchByEntity } from '../api/transactionServer';
import { CommonApiEntities } from './enums';

export interface IProperty {
    id: string;
    name: string;
    project_id: string;
};

const Project = types.model('aProject', {
    id: types.identifier,
    description: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    project_image: types.maybeNull(types.string),
    builder_image: types.maybeNull(types.string),
    properties: types.array(types.frozen<IProperty>())
}).volatile(_ => ({ loading: false })).actions(self => ({
    fillProps(props: IProperty[]) {
        self.properties.push(...props);
    }
}))
    .actions(self => ({
        setProjectProperties: flow(function* () {
            try {
                if (!self.loading && self.properties.length === 0 && !localStorage.getItem('projectInfo')) {
                    self.loading = true;
                    const data = yield getProperties(self.id);
                    if (!(!!data && !!data['data'] && Array.isArray(data['data'])))
                        throw new Error("insert error here");
                    self.fillProps(data['data']);
                }
            }
            catch (err) { console.error(err.message); }
            finally {
                self.loading = false;
            }
        }),
    }))
    .actions(self => ({
        afterAttach() {
            if ((getRoot(self) as Instance<typeof LateStoreModel>).auth.isAuthenticated) { self.setProjectProperties(); }
        }
    }));


const Projects = types.model('Projects',
    {
        projects: types.array(Project),
        currentProject: types.safeReference(Project),
        // selectedPropertyList: types.optional(, []),
    }).volatile(_ => ({ loading: false, current: "" })).actions(self => ({
        fillProjects(projList: SnapshotOut<typeof Project>[]) {
            // This causes ipad error
            self.projects.push(...projList.flatMap(prj => Project.create(prj)));
        },
        setProject(proj: Instance<typeof Project>) {
            self.currentProject = proj;
            // localStorage.setItem("currentProject",proj.id);
        },
        unsetProject() {
            self.currentProject = undefined;
            if (localStorage.getItem('currentProject')) { localStorage.removeItem('currentProject'); }
        }
    })).actions(self => ({
        getProjects: flow(function* () {
            try {
                if (!self.loading && self.projects.length === 0) {
                    self.loading = true;
                    // const data = yield getProjects();
                    const data = yield commonFetchByEntity({ entity : CommonApiEntities.USER_PROJECTS })
                    if (!(!!data && !!data['data'] && Array.isArray(data['data'])))
                        throw new Error("insert error here");
                    self.fillProjects(data['data']);
                    if (self.current !== "") {
                        self.setProject(resolveIdentifier(Project, self.projects, self.current as string)!);
                        self.current = "";
                    }
                }
            }
            catch (err) { console.error(err.message); }
            finally {
                self.loading = false;
            }
        }),
        getCurrentProjectName(projectId: string) {
            const projects = (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.projects;
            const currentProjectName = projects.find(p => p.id === projectId)?.name || projectId;
            return currentProjectName;
        },
        afterCreate() {
            if (self.projects.length === 0 && localStorage.getItem('projectInfo')) {
                self.loading = true;
                try {
                    const initialProjectInfo = JSON.parse(localStorage.getItem('projectInfo') || '{}');
                    self.projects.clear();
                    self.projects.push(...initialProjectInfo.projects);
                    const curr = initialProjectInfo.currentProject;
                    if (curr !== null) {
                        self.setProject(resolveIdentifier(Project, self.projects, curr)!);
                        (getRoot(self) as Instance<typeof LateStoreModel>).menus.getMenu();
                    }
                }
                catch (err) { console.error(err); }
                finally { self.loading = false; }
            }
            else if (self.projects.length === 0 && localStorage.getItem('currentProject')) {
                self.loading = true;
                try {
                    const initialProjectInfo = localStorage.getItem('currentProject')!;
                    self.current = initialProjectInfo;
                    putAsync(getRoot(self)['channel'],
                        {
                            prop: 'projects',
                            path: '/projectInfo',
                            action: 'getProjects'
                        });
                }
                catch (err) { console.error(err); }
                finally { self.loading = false; }
            }
        }
    }));
export default Projects;
