import React, { forwardRef, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { useStore } from '../../models/ProvideModel';
import { Select, Text } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { CommonApiEntities } from '../../models/enums';
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    selectedNavStyle : {
        padding: '0.5em 1em',
        cursor: 'pointer',
        fontWeight: 600,
        fontSize: '0.9em',
        borderRadius: '0px',
        marginBottom: '0.25em',
        backgroundColor: '#FFF',
        color: '#000',
        boxShadow: '0 0 3px rgba(0,0,0,0.1)',
        borderBottom: '0.15em solid #005eff'
    },
    unselectedNavStyle : {
        padding: '0.5em 1em',
        cursor: 'pointer',
        fontWeight: 500,
        fontSize: '0.9em',
        borderRadius: '0px',
        marginBottom: '0.25em',
        backgroundColor: '#ebeced',
        color: '#666',
        '&:hover' : {
            opacity: 0.8
        }
     }
}));

interface SelectItemProps extends React.ComponentPropsWithoutRef<"div"> {
    value: string;
    label: string;
    id: string;
}

const ConfigNavBar = () => {
    const store = useStore();
    const classes = useStyles();
    const history = useHistory();
    const { entity } = useParams<{ entity?: string }>();
    const selectedEntity = entity || CommonApiEntities.USERS;
    const customerRef = useRef<HTMLInputElement>(null);
    const entities = store.configManager.entities;

    const handleEntityNavClick = (id: string) => {
        history.replace(`/config-manager/${id}`);
    };

    const handleCustomerChange = (value: string) => {
        if (!value || value === store.configManager.currentCustomer) return;
         store.configManager.setCustomer(value);
      };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5em 1em',
            borderBottom: '1px solid #ddd',
            backgroundColor: '#F9FAFB',
            alignItems: 'center'
        }}>
            {/* Left: Nav links */}
            <div style={{ display: 'flex', gap: '0.5em' }}>
                {entities.map((e) => (
                    <div
                        key={e.id}
                        className={selectedEntity === e.id ? classes.selectedNavStyle : classes.unselectedNavStyle}
                        onClick={() => handleEntityNavClick(e.id)}
                    >
                        {e.name}
                    </div>
                ))}
            </div>

            {/* Right: Customer Select */}
            <div style={{ display: 'flex', minWidth: 250, alignItems: 'center' }}>
                <span style={{ fontWeight: 600, color: '#585858', marginRight: '0.5em' }}>Customer: </span>
                <Select
                    data={store.configManager.customers.map(c => ({
                        value: c.id,
                        label: c.name,
                        id: c.id
                    }))}
                    value={store.configManager.currentCustomer}
                    onChange={handleCustomerChange}
                    size="xs"
                    searchable
                    filter={(value, item) =>
                        item.label?.toLowerCase().includes(value.toLowerCase()) ||
                        item.value.toLowerCase().includes(value.toLowerCase())
                    }
                    nothingFound="No options found"
                    withinPortal
                    maxDropdownHeight={400}
                    itemComponent={forwardRef<HTMLDivElement, SelectItemProps>(({ value, label, id, ...props }, ref) => (
                        <div ref={ref} {...props} style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
                            <span style={{ fontSize: '0.9em', fontWeight: 500 }}>{label}</span>
                            <span style={{ fontSize: '0.8em', color: '#b9b9b9' }}>{id}</span>
                        </div>
                    ))}
                    ref={customerRef}
                    onFocus={() => { customerRef.current?.select(); }}
                />
            </div>
        </div>
    );
};

export default observer(ConfigNavBar);
