import React, { useMemo } from "react";
import { Button, Group, Tooltip } from "@mantine/core";
import { MRT_ColumnDef } from "mantine-react-table";
import { Instance } from "mobx-state-tree";
import { LateStoreModel } from "../../../models/DataStore";
import { CommonApiEntities } from "../../../models/enums";
import moment from "moment";

export interface BaseBuilderColumns {
    id: string,
    customerId?: string
    name: string,
    builderImage?: string,
    syncCt?: string,
    syncUt?: string,
};

export interface BuilderDetails extends BaseBuilderColumns {
    [K: string]: any;
};

export const useBuilderTableColumnDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<BuilderDetails>[] = (store) => useMemo(() => [
    {
        accessorKey: "id",
        header: "Builder ID",
        enableEditing: true,
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => {
            const isCreateMode = !row.original.id
            return {
                value: store.configManager.currentlyEditingRow?.id ?? row.original.id ?? "",
                disabled: !isCreateMode,
                onChange: (e) => {
                    if (isCreateMode) {
                        store.configManager.setEditingRow({ id: e.currentTarget.value });
                    }
                }
            };
        },

    },
    {
        accessorKey: "name",
        header: "Builder Name",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.name ?? row.original.name ?? "",
            onChange: (e) => store.configManager.setEditingRow({ name: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "description",
        header: "Description",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.description ?? row.original.description ?? "",
            onChange: (e) => store.configManager.setEditingRow({ description: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "builderImage",
        header: "Builder Image",
        enableColumnFilterModes: false,
        Cell: ({ cell }) =>
            cell.getValue() ? (
                <Tooltip label="Photo Preview">
                    <img src={cell.getValue() as string} alt="user" style={{ width: 32, height: 32 }} />
                </Tooltip>
            ) : (
                "-"
            ),
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.builderImage ?? row.original.builderImage ?? "",
            onChange: (e) => store.configManager.setEditingRow({ builderImage: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "syncUt",
        header: "Updated On",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => {
            const dateStr = row.original.syncUt;
            return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
        },
    },
    {
        accessorKey: "syncCt",
        header: "Created On",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => {
            const dateStr = row.original.syncCt;
            return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
        },
    },
    {
        accessorKey: "actions",
        header: "Actions",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => (
            <Group spacing="xs">
                <Button
                    size="xs"
                    color="red"
                    variant="outline"
                    disabled={!row.original.id}
                    style={{ borderRadius: 0 }}
                    onClick={() => store.configManager.deleteEntityItem(CommonApiEntities.BUILDER, row.original.id)}
                >
                    Delete
                </Button>
            </Group>
        ),
    },
], []);