import React, { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../models/ProvideModel";
import MantineTable from '../../../components/requests/MantineTable';
import { CommonApiEntities } from "../../../models/enums";
import LoadingTowers from "../../../components/LoadingTowers";
import { useBuilderTableColumnDef } from "./gridColumns";
import { useTableOptions } from "./tableOptions";
import BuilderToolbarComponent from "./BuilderToolbar";

const Builder = () => {
    const store = useStore();
    const columns = useBuilderTableColumnDef(store);
    const tableOptions = useTableOptions(store);
    const ToolbarComponent = useMemo(() => ({ table }) => <BuilderToolbarComponent table={table} />, []);

    useEffect(() => {
        let isMounted = true;    
        const loadUsers = async () => {
            try {
                await store.configManager.fetchEntityData(CommonApiEntities.BUILDER);
            } catch (error) {
                if (isMounted) { console.error('Failed to load builder:', error); }
            }
        };
        loadUsers();
        return () => {
            isMounted = false;
        };
    }, [store.configManager.currentCustomer]);

    const useFilterListeners = () => { };

    const getFilteredRowCount = useCallback((rowCount: number) => {
        store.configManager.setFilterRowsCount(rowCount);
      }, []);

    return (
        <div style={{ display: "flex", height: "100%", width: "100%", flexGrow: 1, flexShrink: 1 }}>
            {store.configManager.loading ?
                <LoadingTowers title={"Fetching builder"} /> :
                <MantineTable
                    data={store.configManager.builder}
                    columns={columns}
                    setRowCount={getFilteredRowCount}
                    rowCallback={() => { }}
                    ToolbarComponent={ToolbarComponent}
                    useFilterListeners={useFilterListeners}
                    tableOptions={tableOptions}
                />
            }
        </div>
    );
};

export default observer(Builder);
