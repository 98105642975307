import { ActionIcon, CopyButton, Tooltip, rem } from '@mantine/core'
import { IconArrowDown, IconArrowUp, IconCheck, IconCopy } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import JsonViewer from './JsonViewer'
import { useStore } from '../../../models/ProvideModel';
import { ResponseObject } from '../../../models/admin/definitions/interface';
import { observer } from 'mobx-react-lite';

const ResponseContainer = ({ response, responseStatus } : { response: ResponseObject | undefined, responseStatus: string | undefined }) => {
 const store = useStore();
 const preparedResponseStatus = !responseStatus ?  null : ['200','201','success','true'].includes(responseStatus.toString().toLowerCase())
 const [collapse, setCollapse] = useState('0')

 useEffect(() => { 
    response ? setCollapse('60') : setCollapse('0')
  },[response, store.adminModule.currentRequest])

  return (
    <div style={{ overflowY: 'auto', minHeight: `${collapse}%`, width: '100%' }}>
                    <div style={{ background: '#fbe5ba', width: '100%', padding: '0.5em 1em', position: 'sticky', top: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', 
                    borderBottom: preparedResponseStatus === null ? 'none' : preparedResponseStatus ? `0.20em solid rgba(130, 201, 30, 0.5)` : `0.20em solid  rgba(250, 82, 82, 0.5)`, 
                    boxShadow: preparedResponseStatus === null ? 'none' : preparedResponseStatus ? `0px 0px 20px 3px rgba(130, 201, 30, 0.5)` : `0px 0px 15px 3px rgba(250, 82, 82, 0.5)`}}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5em', fontWeight: 600, margin: 0, textAlign: 'left', color: '#2F3E6C', cursor: 'pointer', }} onClick={() => setCollapse(prev => prev === '0' ? '60' : '0')}>
                            <span style={{ marginRight: '0.2em'}}>Response</span>
                            <div style={{display: 'flex', alignItems: 'center', backgroundColor: '#7d725d', cursor: 'pointer', padding: '0em 0.3em'}}>
                            <span style={{ marginRight: '0.2em', fontSize: '0.7em', color: '#FFF' }}>{collapse === '0' ? 'Expand' : 'Collapse'}</span> 
                            {collapse === '0' ? <IconArrowUp size={'0.7em'} color='#FFF'/> : <IconArrowDown size={'0.7em'} color='#FFF'/>}
                            </div>
                        </span>
                        <div style={{ fontWeight: 800, color: (preparedResponseStatus === null) ?  `#000` : preparedResponseStatus ? `rgba(130, 201, 30, 1)` : `rgba(250, 82, 82, 1)` }}>{(preparedResponseStatus === null) ?  null : preparedResponseStatus ? `SUCCESS` : `FAILED` }</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {response ? <span style={{marginRight: '0.5em'}}>
                                <CopyButton value={JSON.stringify(response)} timeout={2000}>
                                    {({ copied, copy }) => (
                                        <Tooltip label={copied ? 'Response copied' : 'Copy response'} withArrow position="right">
                                            <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                                {copied ? (
                                                    <IconCheck style={{ width: rem(16) }} />
                                                ) : (
                                                    <IconCopy style={{ width: rem(16) }} />
                                                )}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </span> : null}
                            <span>{response?.time}</span>
                        </div>
                    </div>
                    <div style={{ display: collapse === '0' ? 'none' : 'flex', maxWidth: '100%', overflow: 'auto', padding: '1em' }}>
                        <JsonViewer jsonResponse={response} refresh={store.adminModule.loading} />
                        {/* <GenericTable data={response?.data || response?.data.data} /> */}
                        {/* { (response && response.data) && <JSONToField data={response.data}/>} #82C91E*/}
                    </div>
                </div>
  )
}

export default observer(ResponseContainer)