import { types, flow, getRoot, Instance, applyPatch, } from 'mobx-state-tree';
import { commonFetch, unitActivityStart, } from '../api/transactionServer';
import { LateStoreModel } from './DataStore';
import moment from 'moment';
// import { notifications } from '@mantine/notifications';
import { ActivityStatuses, CommonApiEntities, } from './enums';
import { activityStatuses } from '../utils/constants';

const __limit = 10000;
const sortBySyncCTFunc = (a: any, b: any) => { return moment(b.syncCt).valueOf() - moment(a.syncCt).valueOf(); };

const UnitActivity = types.model({
    id: types.identifier,
    title: types.optional(types.string, ""),
    blockId: types.string,
    blockName: types.string,
    unitId: types.string,
    unitTitle: types.string,
    activityTypeId: types.string,
    activityTypeTitle: types.string,
    statusId: types.string,
    // statusName: types.optional(types.string, "Status"),
    // TODO: maybe number?
    progress: (types.optional, types.number, 0),
    // progress: (types.optional, types.string, "0"),
    unitTypeId: types.string,
    isEditing: false,
    unitTypeName: types.string,
    floorIdx: types.optional(types.number, -1000),
    floorLabel: types.optional(types.string, "NA"),
}).views(self => ({
    get statusName() {
        //TODO: Implement
        return activityStatuses[self.statusId] || "Invalid";
    },
    get canEdit() {
        //TODO: eventually put your permissions logic here.
        return true;
    }
})).actions(self => ({
    startActivity: flow(function* startActivity() {
        if (!self.isEditing && (self.statusId === ActivityStatuses.PLANNED || self.statusId === ActivityStatuses.CONFIGURED)) {
            try {
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                //TODO: Replace API call here
                // yield Promise.resolve();
                yield unitActivityStart({ projectId: root.projectInfo.currentProject!.id, unitActivityId: self.id });
                applyPatch(self, { op: "replace", path: `/statusId`, value: ActivityStatuses.ACTIVE });
            }
            catch (err) { console.error(err); }
        }
    }),
}));
export const ActivityManager = types.model({
    loading: false,
    unitActivities: types.array(UnitActivity),
    gridKeys: types.array(types.string),
    filteredRowsCount: types.optional(types.number, 0),
}).views(self => ({
    get projectId() {
        // TODO: remove harcoded projectId.
        // return "project_1";
        return (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id;
    },
})).views(self => ({
    get canStart() {
        //TODO: Implement Permissions here.
        return true;
    },
    get unitActivitiesGrid() {
        if (!(self.gridKeys.length && self.unitActivities.length)) { return []; }
        return self.unitActivities.map(ua =>
            self.gridKeys.reduce((acc, k) => ({
                ...acc,
                [k]: Array.isArray(ua[k]) ? [...ua[k]] : ua[k]
            }), { unitActivity: ua }));
    },
})).actions(self => ({
    addGridKeys(val: string[]) {
        self.gridKeys.push(...val);
    },
    setFilterRowsCount(val: number) {
        self.filteredRowsCount = val;
    },
})).actions(self => ({
    getUnitActivities: flow(function* getUnitActivities() {
        self.loading = true;
        self.unitActivities.clear();
        try {
            const { data } = yield commonFetch({ projectId: self.projectId, entity: CommonApiEntities.UNIT_ACTIVITIES, filters: { isDPR: "false", statuses: [ActivityStatuses.PLANNED, ActivityStatuses.CONFIGURED], } });
            const list = data.length > __limit ? data.slice(0, __limit).sort(sortBySyncCTFunc) : data.sort(sortBySyncCTFunc);
            self.unitActivities.replace(list);
        }
        catch (err) { console.error(err); }
        finally { self.loading = false; }
    }),
}));

export interface IActivityManager extends Instance<typeof ActivityManager> { };
