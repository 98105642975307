import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        alignSelf: 'start'
    },
    closeButton: {
        fontSize: "1em",
        fontWeight: 600,
        color: "#EA3434",
        cursor: "pointer",
        padding: '1em',
      },
    ticketTypeGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '1em'
    },
    dropdown: {
        flexGrow: 9,
        // width: 120,
        ["& .mantine-Select-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600, opacity: 0.8 },
        ["& .mantine-Select-input"]: { marginTop: '0.5em', marginBottom: '0.5em', borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed', borderBottom: '0.0525em solid #c9c9c9' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
        // ["& .mantine-Select-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' }
    },
    dropdownButton: {
        // alignSelf: "end",
        // flexGrow: 1,
        borderRadius: 0,
        // marginRight: '2.5em'
        width: 200,
        background: '#005eff'
    },
    downloadText: {
        marginRight: '0.5em'
    },
    tableTitleDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // margin: '1em 0em',
        marginTop: '3em',
        marginBottom: '1em',
        width: '100%'
    },
    tableTitleSmall : { 
        fontSize: '0.65em'
    },
}))

export default useStyles;