import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import Users from './users/Users'
import { useStore } from '../../models/ProvideModel';
import { observer } from 'mobx-react-lite';
import { CommonApiEntities } from '../../models/enums';
import NoDataFound from '../../components/noDataFound/NoDataFound';
import ConfigNavBar from './ConfigNavBar';
import Builder from './builder/Builder';
import Projects from './projects/Projects';
import Properties from './properties/Properties';
import Blocks from './blocks/Blocks';

const validEntities = Object.values(CommonApiEntities).map(v => v.toLowerCase());

const ConfigManager = () => {
    const store = useStore();
    const history = useHistory();
    const { entity } = useParams<{ entity: string }>();
    const [selectedCustomer, setSelectedCustomer] = useState(() => store.auth.customerId);
    const normalizedEntity = (entity || '').toLowerCase().trim();

    useEffect(() => {
        if (!validEntities.includes(normalizedEntity)) {
            history.replace(`/config-manager/${CommonApiEntities.USERS}`);
        } 
    }, [entity, history]);

    useEffect(() => {
        const fetchAllCustomers = async () => {
            try {
                if(selectedCustomer) {
                    await store.configManager.fetchAllCustomers();
                    store.configManager.setCustomer(selectedCustomer);
                    store.configManager.initializeEntities();
                }
            } catch (error) {
                console.error('Error fetching API list:', error);
            }
        };
        fetchAllCustomers();
    }, [])

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <ConfigNavBar />
            <div style={{ flexGrow: 1, height: 'calc(100% - 60px)' }}>
                {(entity || CommonApiEntities.USERS) === CommonApiEntities.USERS?
                    <Users /> :
                    entity === CommonApiEntities.BUILDER?
                    <Builder /> :
                    entity === CommonApiEntities.PROJECTS?
                    <Projects /> :
                    entity === CommonApiEntities.PROPERTIES?
                    <Properties /> :
                    entity === CommonApiEntities.BLOCKS?
                    <Blocks /> :
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '100%'}}>
                        <NoDataFound />
                    </div>
                }
            </div>
        </div>
    )
}

export default observer(ConfigManager)