import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
/* import { useLocation, useHistory } from 'react-router'; */
import { useStore } from '../../models/ProvideModel';
import LoadingTowers from '../../components/LoadingTowers';
import RequestTable from '../../components/requests/MantineTable';
import { gridKeys, tableOptions, uaColumnsDef as columnsDef, } from './amGridColumns';
import ActivityManagerToolbar from './ActivityManagerToolbar';
import useStyles from './ActivityManagerStyles';

/* const __module = "activityManager"; */
/* const __dropdownFields = ['units', 'activityTypes', 'unitTypes', 'blocks', 'floors']; */
const __dropdownFields = ['unitTypes',];

const rowCallback = () => { };
const PlanningModule = () => {
  const store = useStore();
  const classes = useStyles();
  /* const { search } = useLocation();
   * const { push } = useHistory();
   * const searchParams = useRef<URLSearchParams>(new URLSearchParams(search)); */
  const ToolbarComponent = useMemo(() => () => <ActivityManagerToolbar />, []);
  useEffect(() => {
    store.activityManager.addGridKeys(gridKeys);
    /* store.activityManager.setDropdownFields(__dropdownFields);
     * store.activityManager.getDropdownValues(); */
    store.activityManager.getUnitActivities();
  }, []);
  const getFilteredRowCount = useCallback((rowCount: number) => {
    store.activityManager.setFilterRowsCount(rowCount);
    /* setFilteredRowsCount(rowCount) */
  }, []);
  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
        {/* TODO: Implement here for create unit modal*/}
        <div style={{ display: "flex", height: "100%", width: "100%", flexGrow: 1, flexShrink: 1 }}>
          {store.activityManager.loading ?
            <LoadingTowers title={"Fetching UnitActivities"} /> :
            <RequestTable
              data={store.activityManager.unitActivitiesGrid}
              rowCallback={rowCallback}
              setRowCount={getFilteredRowCount}
              columns={columnsDef}
              ToolbarComponent={ToolbarComponent}
              tableOptions={tableOptions}
              useFilterListeners={() => { }}
            >
            </RequestTable>}
        </div>
      </div>
    </div >
  );

};

export default observer(PlanningModule);
