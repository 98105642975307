import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from '@mantine/core';
import { useLocation, useHistory } from 'react-router';
import { useStore } from '../../models/ProvideModel';
import LoadingTowers from '../../components/LoadingTowers';
import RequestTable from '../../components/requests/MantineTable';
import { gridKeys, useTableOptions, useColumnsDef, UnitActivityDetails } from './gridColumns';
import PlanningModuleToolbar from './PlanningModuleToolbar';
import useStyles from './PlanningModuleStyles';
import GenericModal from '../../containers/GenericModal';
import { onPatch } from 'mobx-state-tree';
import NewUnitPopup from '../../containers/planningFormPopup/NewUnitPopup';
import useMantineListener from './PlanningModuleListener';
import ActivityExcelImport from '../../containers/activityExcelImport';

const __module = "planning";
const __dropdownFields = ['units', 'activityTypes', 'unitTypes', 'blocks', 'floors'];

const rowCallback = () => { };
const PlanningModule = () => {
  const store = useStore();
  const classes = useStyles();
  const { search } = useLocation();
  const { push } = useHistory();
  const searchParams = useRef<URLSearchParams>(new URLSearchParams(search));
  const columnsDef = useColumnsDef(store);
  const tableOptions = useTableOptions(store);
  const ToolbarComponent = useMemo(() => ({ table }) => <PlanningModuleToolbar table={table} searchParams={searchParams} />, []);
  useEffect(() => {
    store.planning.addGridKeys(gridKeys);
    store.planning.setDropdownFields(__dropdownFields);
    store.planning.getDropdownValues();
    if(!store.planning.hideUnitActivitiesFlag) {
      store.planning.getUnitActivities();
    }
    const p1 = onPatch(store.planning, ({ op, path, value }) => {
      if (path === '/createUnitPopupIsOpen') {
        if (value) { searchParams.current.set("createUnit", "true") }
        else { searchParams.current.delete("createUnit"); }
        push(store.params.path + searchParams.current.toString().length ? `?${searchParams.current}` : "");
      }
    });
    return () => { p1(); };
  }, []);
  useEffect(() => {
    searchParams.current = new URLSearchParams(search);
    if (searchParams.current.has("createUnit")) {
      store.planning.setCreateUnitPopup(true);
    }
    else {
      store.planning.setCreateUnitPopup();
      if (searchParams.current.get("currentView") === "units") {
        store.planning.setCurrentView("units");
      }
    }
  }, [search]);
  const getFilteredRowCount = useCallback((rowCount: number) => {
    store.planning.setFilterRowsCount(rowCount);
    /* setFilteredRowsCount(rowCount) */
  }, []);
  const onClose = useCallback(() => {
    store.planning.setCreateUnitPopup();
    if( store.planning.bulkImport.isOpen) {
      store.planning.bulkImport.setOpen(false);
    }
  }, []);
  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>

        <GenericModal
          loading={store.planning.loading}
          isOpen={store.planning.createUnitPopupIsOpen || store.planning.bulkImport.isOpen}
          onClose={onClose}
          actions={[]}
          fullWidth
          maxWidth={store.planning.createUnitPopupIsOpen ? "sm" : "md"}
          bgColor="#999999"
        >
          {store.planning.createUnitPopupIsOpen ?
           <div className={classes.popup}>
             <NewUnitPopup
               onClose={onClose}
             />
           </div>
          :  store.planning.bulkImport.isOpen
          ?
           (
             <ActivityExcelImport importModel={store.planning.bulkImport} onClose={onClose} />
           )
          : null
          }
        </GenericModal>
        {/* TODO: Implement here for create unit modal*/}
        <div style={{ display: "flex", height: "100%", width: "100%", flexGrow: 1, flexShrink: 1 }}>
          {store.planning.loading ?
           <LoadingTowers title={"Fetching UnitActivities"} /> :
           <RequestTable
             data={store.planning.unitActivitiesGrid}
             rowCallback={rowCallback}
             setRowCount={getFilteredRowCount}
             columns={columnsDef}
             ToolbarComponent={ToolbarComponent}
             tableOptions={tableOptions}
             useFilterListeners={useMantineListener}
           >
           </RequestTable>}
        </div>
      </div>
    </div >
  );

};

export default observer(PlanningModule);
