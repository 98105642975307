import React from 'react';
import { Button, Group, Stack, Text, Grid, ScrollArea, Tooltip, TextInput } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { IconDownload, IconAlertTriangle } from '@tabler/icons-react';
import { IBulkImport, IBulkImportRequest } from '../../../models/Planning';
import useStyles from '../ActivityExcelImportStyles';
import DropOrUpload from '../UploadDropZone';
import BulkImportRequestHistoryRow from '../JobHistoryRow';
import { useStore } from '../../../models/ProvideModel';
import { UnitActivityBulkActions } from '../../../models/enums'

const CreateActivities = ({ importModel }: { importModel: IBulkImport }) => {
    const classes = useStyles();
    const store = useStore();
    const defaultFileName = `${store.auth.customerName}-${store.projectInfo.currentProject?.name}-Template.xlsx`
    const [customFileName, setCustomFileName] = React.useState(defaultFileName);
    return (
        <Stack align="center"
            w="100%" spacing="xs" p=".5rem">
            <Grid w="100%" mx="2rem" align="flex-start">
                <Grid.Col span={12} style={{ marginBottom: '1em' }}>
                    <Group className={classes.ticketTypeGroup}>
                        <div style={{ fontSize: '0.9525em', fontWeight: 600 }}>
                            Download an empty template (excel) for creating activities in bulk.
                        </div>
                    </Group>
                    <Group className={classes.ticketTypeGroup}>
                        <TextInput
                            label={"Filename (optional)"}
                            placeholder={defaultFileName}
                            value={customFileName}
                            onChange={(event) => {
                                let inputValue = event.target.value
                                    .replace(/\s+/g, "_") // Replace spaces with underscores
                                    .replace(/[^a-zA-Z0-9_-]/g, ""); // Allow only letters, numbers, "_" and "-"
                                setCustomFileName(inputValue);
                            }}
                            onBlur={() => {
                                if (!customFileName.endsWith(".xlsx")) {
                                    setCustomFileName((prev) => prev !== "" ? prev + ".xlsx" : defaultFileName);
                                }
                            }}
                            style={{ width: '50%' }}
                            // disabled={!selectedProcessDefID || !selectedTaskKey}
                            sx={{
                                ["& .mantine-InputWrapper-wrapper"]: { width: '100%' },
                                ["& .mantine-TextInput-root"]: { width: '100%' },
                                ["& .mantine-TextInput-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600, opacity: 0.8 },
                                ["& .mantine-TextInput-input"]: { marginTop: '0.5em', marginBottom: '0.5em', border: 0, padding: 0, borderBottom: '0.0525em solid #005eff', borderRadius: 0, fontSize: "1em", '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed', borderBottom: '0.0525em solid #c9c9c9' }, '&[data-with-icon]': { paddingLeft: 0 } },
                                ["& .mantine-TextInput-icon"]: { justifyContent: 'center' }
                            }}
                        />
                        <Tooltip label="Download Template File (Excel)">
                            <Button className={classes.dropdownButton}
                                //   disabled={importModel.disabled} 
                                onClick={() => { importModel.downloadTemplate(customFileName); }}>
                                <span className={classes.downloadText}>Download Template</span>
                                <IconDownload size="1.5em" />
                            </Button>
                        </Tooltip>
                    </Group>
                </Grid.Col>
            </Grid>
            <Grid w="100%" mx="2rem" align="flex-start" mt={'1em'}>
                <Grid.Col span={12} >
                    <Group>
                        <DropOrUpload
                            label="Upload Excel Template Here"
                            model={importModel}
                            caption={'Ensure fields are filled as per guidelines'}
                            //   disabled={!importModel.selectedTicketType}
                            actionType={UnitActivityBulkActions.CREATE}
                        />
                    </Group>
                </Grid.Col>
            </Grid>
            <div className={classes.tableTitleDiv}>
                <Text fz="lg" fw={750} mr={'0.5em'}>Jobs</Text>
                <span className={classes.tableTitleSmall}>(Showing last 10 results)</span>
            </div>
            <Grid sx={{ border: '0.05em solid #585858' }} justify="space-around" mx="2rem" w="100%">
                <Grid.Col span={2}><Text fw={600}>Job ID</Text></Grid.Col>
                <Grid.Col span={4}><Text fw={600}>Request Type</Text></Grid.Col>
                <Grid.Col span={4}><Text fw={600}>Job Status</Text></Grid.Col>
                <Grid.Col span={2}> <Text fw={600} align="center">Check & Download</Text> </Grid.Col>
            </Grid>
            {importModel.history.length && importModel.history.filter((req: IBulkImportRequest) => req.requestType === UnitActivityBulkActions.CREATE).length ?
                <ScrollArea h={200} type="auto" w="100%" >
                    {importModel.history.filter((request: IBulkImportRequest) => request.requestType === UnitActivityBulkActions.CREATE).map((request: IBulkImportRequest) => (
                        <BulkImportRequestHistoryRow key={request.requestId} request={request} />
                    ))}
                </ScrollArea>
                :
                <Group position="center" align="center" w="100%" p="1rem" bg="#f9f9f9" mih={200}>
                    {/* <NoDataFound title='No jobs found.' titleStyle={{ display: 'flex', fontSize: '0.75em', fontWeight: 400 }}/> */}
                    <IconAlertTriangle size={'1.25em'} /> <span>No jobs found.</span>
                </Group>
            }
        </Stack>
    );

};

export default observer(CreateActivities);

