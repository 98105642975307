import React, { useCallback, useRef, useState } from 'react';
import { Button, CloseButton, Group, Tooltip } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../models/ProvideModel';
import { MRT_TableInstance, createRow } from 'mantine-react-table';
import { UserDetails } from './gridColumns';
import { IconFileExport, IconFileImport, IconHistory, IconPlus, IconRefresh } from '@tabler/icons-react';
import { CommonApiEntities } from '../../../models/enums';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import GenericModal from '../../../containers/GenericModal';
import JSONPretty from 'react-json-pretty';
import { Typography } from '@material-ui/core';

const MAX_SIZE_MB = 5 * 1024 * 1024;
const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822', /* base background color */
    base01: '#383830'
};

const UserToolbarComponent = ({ table }: { table: MRT_TableInstance<UserDetails> }) => {
    const store = useStore();
    const buttonStyle = { borderRadius: 0 }
    const openRef = useRef<() => void>(null);
    const [openModal, setOpenModal] = useState(false)

    const createNewRow = useCallback(() => {
        table.setPageIndex(0);
        table.refs.tableContainerRef.current.scroll(0, 0);
        const row = createRow(table);
        table.setCreatingRow(row);
        if (!table.getState().columnVisibility['mrt-row-actions']) {
            table.setColumnVisibility({ 'mrt-row-actions': true });
        }
    }, []);


    const handleImport = async (files: File[]) => {
        const file = files[0];
        if (!file) return;
        try {
             await store.configManager.uploadExcel(store.configManager.currentCustomer, file)
             await store.configManager.fetchEntityData(CommonApiEntities.USERS);
        } catch (error) {
            console.error(error);
            throw error
        }
    };

    const handleModalClose = () => setOpenModal(false)

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <span style={{ fontSize: "1.7em", display: "flex", justifyContent: "flex-start", fontWeight: 700, color: "#2F3E6C" }}>USERS &nbsp; 
                <span style={{ fontSize: '0.7em', color: '#C9C9C9', alignSelf: 'center' }}>{`(${store.configManager.filteredRowsCount})`}</span>
            </span>
            <Group spacing="xs">
                <Button
                    size="xs"
                    leftIcon={<IconPlus width={'1.5em'} height={'1.5em'} />}
                    onClick={createNewRow}
                    color='teal'
                    style={buttonStyle}
                >
                    Add New User
                </Button>
                <Tooltip
                    label={'Refresh data'}
                // position='right'
                >
                    <Button
                        size="xs"
                        leftIcon={<IconRefresh width={'1.5em'} height={'1.5em'} />}
                        onClick={() => store.configManager.fetchEntityData(CommonApiEntities.USERS)}
                        style={buttonStyle}
                    >
                        Refresh
                    </Button>
                </Tooltip>
                <Button
                    size="xs"
                    leftIcon={<IconFileExport width={'1.5em'} height={'1.5em'} />}
                    onClick={() => store.configManager.getExcelTemplate() }
                    style={buttonStyle}
                >
                    Export Template
                </Button>
                <Dropzone
                    openRef={openRef}
                    onDrop={(files) => handleImport(files)}
                    maxSize={MAX_SIZE_MB}
                    accept={[MIME_TYPES.xls, MIME_TYPES.xlsx]}
                    activateOnClick={false}
                    activateOnDrag={false}
                    styles={{ root: { display: 'none' } }} // Completely hide the UI
                >{null}</Dropzone>
                <Button
                    size="xs"
                    leftIcon={<IconFileImport width={'1.5em'} height={'1.5em'} />}
                    onClick={() => openRef.current?.()}
                    style={buttonStyle}
                >
                    Import Users
                </Button>
                <Tooltip label={store.configManager.lastBulkImportInfo ? `Import history` : `No import history`}>
                    <IconHistory width={'1.5em'} height={'1.5em'}
                        style={{
                            cursor: store.configManager.lastBulkImportInfo ? 'pointer' : 'default',
                            opacity: store.configManager.lastBulkImportInfo ? 1 : .3,
                        }}
                        onClick={() => { if (store.configManager.lastBulkImportInfo) { setOpenModal(true); } }} />
                </Tooltip>
            </Group>
            <GenericModal
                actions={[]}
                isOpen={openModal}
                onClose={handleModalClose}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Group position='apart' w="100%" style={{ display: 'flex', alignItems: 'center',  width: '100%', position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#FFF' }}>
                        <Typography title='BulkImport' variant='h5' style={{ padding: '0.5em', fontWeight: 'bold' }}>Import History</Typography>
                        <CloseButton color='red' title='Close' onClick={handleModalClose} style={{ marginRight: '0.5em' }} />
                    </Group>
                    <div style={{ width: '100%', padding: '0em 1em'}}>
                    <JSONPretty
                        id="json-pretty"
                        data={store.configManager.lastBulkImportInfo}
                        space={4}
                        themeClassName="JSONPretty.monikai"
                        theme={theme}
                        style={{ width: '100%', wordBreak: 'break-word' }}
                    />
                    </div>
                </div>
            </GenericModal>
        </div>
    );
};

export default observer(UserToolbarComponent);
