
import React from 'react'
import { useStore } from '../../../../models/ProvideModel';
import { CommonApiEntities } from '../../../../models/enums';
import { ActionIcon, Button, TextInput } from '@mantine/core';
import { IconEyeOff } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';

const RevealPassword = ({ row }) => {
            const store = useStore();
            const [revealed, setRevealed] = React.useState(false);
            const [password, setPassword] = React.useState("");
        
            const handleRevealPassword = async () => {
                try {
                    const pwd = await store.configManager.getMasterPassword(CommonApiEntities.USERS, row.original.id);
                    setPassword(row.original.password || "");
                    setRevealed(true);
                } catch (err) {
                    console.error("Failed to fetch password", err);
                }
            };
        
            if (!revealed) {
                return (
                    <Button size="xs" variant="subtle" color="blue" onClick={handleRevealPassword}>
                        Reveal Password
                    </Button>
                );
            }
        
            return (
                <TextInput
                    type="text"
                    value={password}
                    readOnly
                    rightSection={
                        <ActionIcon onClick={() => setRevealed(false)}>
                            <IconEyeOff size={16} />
                        </ActionIcon>
                    }
                />
            );
        }

export default observer(RevealPassword);