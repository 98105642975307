import axios from 'axios';
import config from "../configs/clientConfig";
import { v4 as uuidv4 } from 'uuid';
import { notifications } from '@mantine/notifications';
import { ClaimActions, CommonApiEntities, ProgressReportFrequencies } from '../models/enums';
import { showErrorNotification } from '../utils/utils';

const successNotificationColor = 'teal';
const errorNotificationColor = 'red';
const defaultNotificationColor = '#005eff'

// stupid brigade hardcoding
export async function uploadImage(file: File) {
    try {
        let form = new FormData();
        form.append('uploadType', 'image');
        form.append('fileName', `brigade/${file.name.replace(/[\s@]+/g, "_")}`);
        form.append('file', file);
        const res = await axios.post(config.TransactionServerUrl + 'files/upload',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        return { ...res.data, fileName: res.data.fileName.replace("brigade/", "") };
        // return { ...res.data, fileName: res.data.fileName };
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function updateForm(payload: { [K: string]: any; }) {
    try {
        const res = await axios.post(config.TransactionServerUrl + 'portal/updateFormData', { ...payload });
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function getStartProcessDefinitionKeys({ user, customer, project }: { user: string; customer: string; project: string }) {
    try {
        const res = await axios.get(config.TransactionServerUrl + `process-engine/process/definition/list/${customer}/${user}/${project}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

// export async function getAssignedTasks({ customer, userId }: { customer: string; userId: string }) {
//     try {
//         const res = await axios.get(`${config.TransactionServerUrl}process-engine/task/list/assignee/${customer}/${userId}`);
//         console.log(res.data);
//         return res.data;
//     }
//     catch (err) {
//         console.error(`Error response: ${err}`);
//         throw err;
//     }
// }

// export async function getInitiatedTasks({ customer, userId }: { customer: string; userId: string; }) {
//     try {
//         const res = await axios.get(`${config.TransactionServerUrl}process-engine/task/list/initiator/${customer}/${userId}`);
//         console.log(res.data);
//         return res.data;
//     }
//     catch (err) {
//         console.error(`Error response: ${err}`);
//         throw err;
//     }
// }

// TODO: Formalize later into different modules.
export async function startProcessByDefinitionKey({ processDefinitionKey: typeId, processDetails, ...rest }: { userId: string; tenant: string; processDefinitionKey: string; processDetails: { [K: string]: any; }; projectId: string; processStartForm?: { [K: string]: any; } }) {
    try {
        // incidentModuleMainForm: null, processStartForm: null
        // requestModuleMainForm: processDetails,
        const res = await axios.post(config.TransactionServerUrl + 'process-engine/process/start', { typeId, requestModuleMainForm: processDetails, ...rest });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function saveFormByUserAndTask({ customer, userId, taskId, projectId, payload, moduleId }: { projectId: string; customer: string; userId: string; taskId: string; payload: { [K: string]: any; }; moduleId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/form/save/${customer}/${moduleId}/${userId}/${projectId}/${taskId}`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function submitForm({ customer, userId, taskId, outcome, payload, moduleId, ticketId }: { customer: string; userId: string; taskId: string; payload: { [K: string]: any; }; outcome?: string; property?: string; moduleId: string; ticketId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/task/submit`, {
            taskId,
            userId,
            moduleId,
            ticketId,
            tenant: customer,
            outcome: outcome || "",
            // propertyId: property,
            formInput: { ...payload }
        });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function getTaskDetailByTaskId({ customer, taskId }: { customer: string; taskId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/task/${customer}/${taskId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function listCommentsForProcess({ processInstanceId }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/comment/${processInstanceId}/list`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}


export async function submitComment({ processInstanceId, userId, commentType, commentMessage, customerId, projectId, moduleId }) {
    try {
        const createdAt = Date.now();
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/comment/add/${customerId}`, {
            ticketId: processInstanceId, userId, commentType, commentMessage, projectId, moduleId
        });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function addNotificationToken({ customerId, user, token }) {
    try {
        let form = new FormData();
        form.append('customerName', customerId);
        form.append('userID', user);
        form.append('token', token);
        const res = await axios.post(config.TransactionServerUrl + 'common/apis/gcm/token/save',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        // throw err;
    }
}

export async function removeNotificationToken({ customerId, user, token }) {
    try {
        let form = new FormData();
        form.append('customerName', customerId);
        form.append('userID', user);
        form.append('token', token);
        const res = await axios.delete(config.TransactionServerUrl + 'common/apis/gcm/token/remove',
            {
                headers: { "Content-Type": "multipart/form-data" },
                data: form
            }
        );
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        // throw err;
    }
}

export async function getStartForm({ customerId, processDefKey }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/process/start/form/${customerId}/${processDefKey}`);
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function uploadFileV2({ file, isDraft, fileId }: { file: File, isDraft?: boolean, fileId?: string }) {
    try {
        let form = new FormData();
        form.append('file', file);
        form.append('isDraft', `${isDraft || true}`);
        if (fileId) { form.append('fileId', `${fileId}`); } //adding this as it is required for storing new versions of file
        const res = await axios.post(config.TransactionServerUrl + 'files/upload/v2',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        notifications.show({ title: 'Uploaded!', message: 'File uploaded successfully.' })
        return res.data.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'File could not be uploaded.', color: errorNotificationColor })
        throw err;
    }
}

export async function downloadFile({ fileId, version, fileName }: { fileId: string; version?: number; fileName: string; }) {
    try {
        const res = await axios.get(version !== undefined ? `${config.TransactionServerUrl}files/download/v2?fileId=${fileId}&versionId=${version}` : `${config.TransactionServerUrl}files/download/v2?fileId=${fileId}`, {
            responseType: 'blob'
        });
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}
export async function deleteImage({ }) {
}

export async function getFileHistory({ fileId }: { fileId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}files/history?fileId=${fileId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

/**API to fetch dynamic drop down values from backend */
export async function getDropdownOption({ customerId, variableType, projectId }: { customerId: string, variableType: string | null; projectId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/reference/variable/${customerId}/${projectId}/${variableType}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

/**API to update core fields data in ticket.requests */
export async function updateCoreFields({ ticketId, userId, moduleId, tenant, requestModuleMainForm }: { ticketId: string, userId: string, moduleId: string, tenant: string, requestModuleMainForm: { dueDate?: string, priority?: string, title?: string, description?: string } }) {
    const data = { ticketId, userId, moduleId, tenant, requestModuleMainForm }
    try {
        const res = await axios.put(`${config.TransactionServerUrl}process-engine/process/fields/update`,
            data,
            { headers: { "Content-Type": "application/json" } });
        console.log(res.data);
        return res.data;
    } catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

// API to get general form for a ticket
export async function getGeneralForm({ customerId, ticketId, formKey, projectId }) {
    try {
        /*
          Try the new API first. If it fails, fallback to original api endpoint
          TODO: Migrate all general forms and then remove the fallback
          process-engine/custom/form/v2/myhome/sayuk/3010001/myhomeSafetyCustomGeneralForm'

        */
        try {
            const res = await axios.get(`${config.TransactionServerUrl}process-engine/custom/form/v2/${customerId}/${projectId}/${ticketId}/${formKey}`);
            console.log(res.data);
            return res.data;
        }
        catch (err) {
            console.error("New API failed. Falling back to old API");
            const res = await axios.get(`${config.TransactionServerUrl}process-engine/custom/form/${customerId}/${ticketId}/${formKey}`);
            console.log(res.data);
            return res.data;
        }
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}


export async function getFeatureConfig({ payload, tenant, feature }: { tenant: string; feature: string; payload: { [K: string]: any; } }) {
    try {
        // incidentModuleMainForm: null, processStartForm: null
        // requestModuleMainForm: processDetails,
        const res = await axios.post(config.TransactionServerUrl + `feature/config/${tenant}/${feature}`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function updateAssignee({ ...payload }: { tenant: string; moduleId: string; ticketId: string; taskId: string; oldAssignee: string; newAssignee: string; userId: string; }) {
    try {
        const res = await axios.post(config.TransactionServerUrl + `process-engine/task/update`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}


export async function createGeneralAttachment({ ...payload }: { tenant: string; moduleId: string; ticketId: string; fileDetails: any[] }) {
    console.log('ran')
}

export async function submitAttachmentComment({ processInstanceId, userId, commentType, commentMessage, customerId, projectId, moduleId, attachments }) {
    try {
        const createdAt = Date.now();
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/comment/add/${customerId}`, {
            ticketId: processInstanceId, userId, commentType, commentMessage, projectId, moduleId, attachments
        });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function getTickets({ tenant, moduleId, ticketId, projectId }: { tenant: string; moduleId: string; ticketId?: string; projectId: string; }) {
    try {
        const res = await axios.get(ticketId ? `${config.TransactionServerUrl}process-engine/ticket/${tenant}/${projectId}/${moduleId}/${ticketId}` : `${config.TransactionServerUrl}process-engine/ticket/list/${tenant}/${projectId}/${moduleId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
};

export async function getHistory({ tenant, ticketId }: { tenant: string; ticketId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/history/list/${tenant}/${ticketId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function getNovuSubscription({ customerId, userId }: { customerId: string; userId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}novu/apis/subscription/keys?customerID=${customerId}&userID=${userId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }

}

export async function getCompletedTasksByTicket({ tenant, ticketId }: { tenant: string; ticketId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/task/completed/${tenant}/${ticketId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

// Tag Requests
export interface ITagRequestPayload {
    ticketId?: string;
    userId: string;
    moduleId: string;
    tagId: string;
    newTagId?: string;
};


export async function listTags({ tenant }: { tenant: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/tag/list/${tenant}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function renameTag({ tenant, payload }: { tenant: string; payload: ITagRequestPayload; }) {
    try {
        const res = await axios.put(`${config.TransactionServerUrl}process-engine/tag/rename/${tenant}`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}
export async function addTag({ tenant, payload }: { tenant: string; payload: ITagRequestPayload; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/tag/add/${tenant}`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function removeTag({ tenant, payload }: { tenant: string; payload: ITagRequestPayload; }) {
    try {
        const res = await axios.delete(`${config.TransactionServerUrl}process-engine/tag/remove/${tenant}`, { data: { ...payload } });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export interface IFeatureConfigApiPayload {
    projectIdList?: string[];
    moduleIdList?: string[];
    ticketDefinitionList?: string[];
    featureName?: string;
};

export async function getFeatureConfig2({ payload, tenant, feature }: { tenant: string; feature?: string; payload: IFeatureConfigApiPayload }) {
    try {
        // incidentModuleMainForm: null, processStartForm: null
        // requestModuleMainForm: processDetails,
        const res = await axios.post(config.TransactionServerUrl + `feature/config/list/${tenant}`, feature ? { ...payload, featureName: feature } : { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export interface IWatcherRequest {
    op: 'add' | 'remove';
    tenant: string;
    ticketId: string;
    userId: string;
    moduleId: string;
    watcherId: string;
};
export async function updateWatcherForTicket({ op, tenant, ...rest }) {
    try {
        let res;
        if (op === 'add') {
            res = await axios.post(`${config.TransactionServerUrl}process-engine/watcher/${op}/${tenant}`, { ...rest });
        }
        else {
            res = await axios.delete(`${config.TransactionServerUrl}process-engine/watcher/${op}/${tenant}`, { data: rest });
        }
        // const res = await axios.post(`${config.TransactionServerUrl}process-engine/watcher/${op}/${tenant}`, { ...rest });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function checkPermission({ customerId, moduleId, projectId, ...rest }: { objectType: string; objectId: string; subjectType: string; subjectId: string; permission: string; projectId: string; moduleId?: string; customerId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}permissions/check-permission`,
            moduleId ? {
                customerId,
                moduleId,
                projectId,
                request: { ...rest }
            } : {
                customerId,
                projectId,
                request: { ...rest }
            });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function deleteTicket({ tenant, ticketId, moduleId, isAdhoc }: { tenant: string; ticketId: string; moduleId: string; isAdhoc: boolean; }) {
    try {
        const res = await axios.delete(`${config.TransactionServerUrl}process-engine/process/delete/${tenant}/${moduleId}/${ticketId}?isAdhoc=${isAdhoc}`);
        console.log(res.data);
        notifications.show({ title: 'Success!', message: 'Ticket deleted succesfully.', color: successNotificationColor })
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'Ticket could not be deleted', color: errorNotificationColor })
        throw err;
    }
}

export async function listPermissionedResources({ customerId, projectId, moduleId, ...rest }: { resourceType: string; subjectType: string; subjectId: string; permission: string; customerId: string; projectId: string; moduleId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}permissions/lookup-resources`,
            {
                customerId,
                projectId,
                moduleId,
                request: { ...rest }
            });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

// TODO: eventually make entity an enum to limit the possibilities
export async function commonFetch({ projectId, entity, filters }: { projectId: string; entity: CommonApiEntities; filters?: { [K: string]: any; }; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}common/apis/${entity}?projectId=${projectId}${filters ? `&${Object.keys(filters).map(k => `${k}=${filters[k]}`).join("&")}` : ""}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}
/*
  Sample form data:
  { 'customer_id': 'myhome',
  'userId': 'MHT_CHK_002',
  'opAction': 'create_unit',
  'description': 'Jai Hind',
  'blockId': 'BLK_E',
  'unitType': 'apt',
  'title': 'E-001',
  'unitId': 'apt_BLK_E_U02'}
*/
export async function createUnit({ customerId, id, ...rest }: { customerId: string; userId: string; description: string; blockId: string; unitType: string; title: string; floorIdx: number; id?: string; }) {
    try {
        let form = new FormData();
        if (id) {
            form.append('unitId', id);
        }
        else {
            form.append('unitId', uuidv4());
        }
        form.append('opAction', 'create_unit');
        form.append("customer_id", customerId);
        Object.keys(rest).forEach(k => {
            form.append(k, rest[k]);
        });
        const res = await axios.post(config.TransactionServerUrl + 'oplog/createUnit',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        notifications.show({ title: 'Failed!', message: err?.errorMessage || 'Unit could not be created', color: errorNotificationColor })
        throw err;
    }
}

export async function createUnitActivity({ customerId, ...rest }: { customerId: string; userId: string; propertyId: string; unitId: string, activityTypeId: string, unitActivityId?: string, titleSuffix?: string, isDPR: boolean, plannedTotalQuantity: number | undefined | null, planStart?: string | undefined | null, planEnd?: string | undefined | null }) {
    try {
        let form = new FormData();
        if (!rest.unitActivityId) {
            form.append('unitActivityId', uuidv4());
        }
        form.append('opAction', 'create_unit_activity');
        form.append("customer_id", customerId);
        Object.keys(rest).forEach(k => {
            if (rest[k]) { form.append(k, rest[k]); }
        });
        const res = await axios.post(config.TransactionServerUrl + 'oplog/createUnitActivity',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        notifications.show({ title: 'Failed!', message: err?.errorMessage || 'Unit activity could not be created!', color: errorNotificationColor })
        throw err;
    }
}

export async function deleteFile({ fileId, versionId }: { fileId: string, versionId?: string | number }) {
    try {
        let form = new FormData();
        form.append('fileId', `${fileId}`);
        // form.append('isDraft', `${isDraft || true}`);
        if (versionId) { form.append('versionId', `${versionId}`); } //adding this as it is required for storing new versions of file
        const res = await axios.delete(config.TransactionServerUrl + 'files/delete', {
            data: form,
            headers: { "Content-Type": "multipart/form-data" }
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        notifications.show({ title: 'Deleted!', message: 'File deleted successfully.' });
        return res.data.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'File could not be deleted.', color: errorNotificationColor })
        throw err;
    }
}

/** Wrapper API for ticket list that consumes multiple project, userId and ticketType */
export async function getTicketsForMultipleProjects({ tenant, projectIds, userId, ticketType }: { tenant: string; projectIds: string[]; userId?: string, ticketType?: string }) {
    try {
        let url = `${config.TransactionServerUrl}process-engine/ticket/list/multiple-projects/${encodeURIComponent(tenant)}/requests?projectIds=${encodeURIComponent(projectIds.join(','))}`;
        if (userId && ticketType) url += `&userId=${encodeURIComponent(userId)}&ticketType=${encodeURIComponent(ticketType)}`;
        else if (userId) url += `&userId=${encodeURIComponent(userId)}`;
        else if (ticketType) url += `&ticketType=${encodeURIComponent(ticketType)}`;

        // const res = await axios.get(url, { headers: { 'Authorization' : 'Basic FBT_TX_SERVER_KOTLIN'}});
        const res = await axios.get(url);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
};

/** API to rename a file */
export const renameFile = async ({ fileId, fileName }: { fileId: string, fileName: string }) => {
    try {
        let url = `${config.TransactionServerUrl}/files/rename`;
        const data = { fileId, fileName }
        const res = await axios.put(url, data, { headers: { "Content-Type": "application/json" } });
        return res.data;
    } catch (error) {
        console.error(`Error response from fileRename: ${error}`);
        throw error;
    }
}

export async function fetchWBSActivities({ projectId, unitActivityIds, frequency, targetPeriod }: { projectId: string; unitActivityIds?: string[]; frequency: string, targetPeriod?: string }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}dpr/apis/wbs?projectId=${projectId}&frequency=${frequency}${unitActivityIds?.length ? `&unitActivityIds=${unitActivityIds}` : ''}${targetPeriod?.length ? `&targetPeriod=${targetPeriod}` : ''}`);
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

//To upsert WBS targets
export async function upsertTargets({ projectId, scheduleList }: { projectId: string, scheduleList: { unitActivityId: string, frequency: string, targetPeriod: string, quantity: number }[] }) {
    try {
        const res = await axios.post(config.TransactionServerUrl + 'dpr/apis/wbs',
            { projectId, scheduleList },
            { headers: { "Content-Type": "application/json" } }
        );
        notifications.show({ title: 'Success', message: 'Targets saved successfully!', color: successNotificationColor })
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        if (err.response) {
            if (err.response.data) {
                notifications.show({ title: 'Failed!', message: err.response.data || 'Sorry, Targets could not be saved!', color: errorNotificationColor })
                throw err;
            } else {
                notifications.show({ title: 'Failed!', message: err.message || 'Sorry, Targets could not be saved!', color: errorNotificationColor })
                throw err;
            }
        } else if (err.request) {
            notifications.show({ title: 'Failed!', message: err.request.response || 'Sorry, Targets could not be saved! Check your network connection', color: errorNotificationColor })
            throw err;
        } else {
            notifications.show({ title: 'Failed!', message: err.message || 'Sorry, Targets could not be saved! Something went wrong', color: errorNotificationColor })
            throw err;
        }
    }
}

export async function getDPRReport({ projectId, targetPeriod, frequency }: { projectId: string; targetPeriod: string; frequency?: ProgressReportFrequencies }) {
    // /dpr/apis/reports?projectId=mang&targetPeriod=2024-11-11&frequency=DAY
    try {
        const res = await axios.get(`${config.TransactionServerUrl}dpr/apis/reports?projectId=${projectId}&targetPeriod=${targetPeriod}${frequency ? `&frequency=${frequency}` : ""}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }

}

export async function claimOrUnclaimTask({ projectId, ticketId, moduleId, taskId, tenant, userId, action }: { action: ClaimActions; projectId: string; ticketId: string; moduleId: string; taskId: string; userId: string; tenant: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/task/${action === ClaimActions.CLAIM ? "claim" : "unclaim"}`,
            { projectId, ticketId, moduleId, taskId, tenant, userId },
            { headers: { "Content-Type": "application/json" } }
        );
        console.log(res.data);
        notifications.show({ title: 'Success', message: `Successfully ${action === ClaimActions.CLAIM ? "Claimed" : "Unclaimed"} Task!`, color: successNotificationColor })
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        notifications.show({ title: 'Failed!', message: err?.errorMessage || `Task could not be ${action === ClaimActions.CLAIM ? "claimed" : "unclaimed"}!`, color: errorNotificationColor })
        throw err;
    }
}

export async function downloadRequestTemplate({ tenant, projectId, moduleId, ticketTypeId, fileName }: { tenant: string; projectId: string; moduleId: string; ticketTypeId: string; fileName?: string; }) {
    try {
        const filename = fileName || (ticketTypeId + ".xlsx");
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/export/template/${tenant}/${projectId}/${moduleId}/${ticketTypeId}?fileName=${filename}`, {
            responseType: 'blob'
        });
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}


export async function importRequestTickets({ tenant, projectId, moduleId, ticketTypeId, file }: { tenant: string; projectId: string; moduleId: string; ticketTypeId: string; file: File; }) {
    try {
        let form = new FormData();
        form.append('file', file);
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/importTickets/${tenant}/${projectId}/${moduleId}/${ticketTypeId}`,
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        notifications.show({ title: 'Uploaded!', message: 'File uploaded successfully.' })
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'File could not be uploaded.', color: errorNotificationColor })
        throw err;
    }
}

export async function downloadBulkUploadStatusFile({ filename, reqId }: { filename: string; reqId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/fetchImportedTicketBulkUploadFile?requestID=${reqId}`, {
            responseType: 'blob'
        });
        console.log("res", res, res.statusText);
        if (res.statusText.toLowerCase() === "ok") {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        return res.statusText;
    }
    catch (err) {
        console.error(`Error response: ${err} ${JSON.stringify(err.response)}`);
        throw err;
    }
}

export async function shareReportViaWhatsapp({ fileName, toNumber, templateName = "snag_report", language = (config.language || "en"), payload }: { fileName: string; toNumber: number; templateName?: string; language?: string; payload: any[]; }) {
    try {
        const res = await axios.post(config.TransactionServerUrl + 'message/send/whatsApp', {
            phoneNumber: '+91' + toNumber,
            templateName,
            language,
            variables: [...payload, fileName],
        });
        notifications.show({ title: 'Shared message!', message: `Sent report to +91${toNumber} successfully.`, color: successNotificationColor });
        return res.data;
    }
    catch (err) {
        // console.error(`Error response: ${err} ${JSON.stringify(err.response)}`);
        // const { errorMessage } = err.response.data
        // notifications.show({ title: 'Failed!', message: errorMessage || 'Report could not be shared.', color: errorNotificationColor })
        // throw err;
        if (err.response) {
            var errorMessage = ""
            if (err.response.data) {
                console.log("Response with data: " + JSON.stringify(err.response.data) + " status: " + err.response.status);
                if (JSON.stringify(err.response.data).includes('The Content Variables parameter is invalid')) { errorMessage = 'Certain special characters are not allowed for whatsApp content.' }
                notifications.show({ title: 'Failed!', message: errorMessage || 'Report could not be shared.', color: errorNotificationColor })
            } else {
                console.log("Response without data: " + err.message + " status: " + err.response.status);
                notifications.show({ title: 'Failed!', message: err.message || 'Report could not be shared.', color: errorNotificationColor })
            }
        } else if (err.request) {
            console.log("Request: " + JSON.stringify(err.request));
            notifications.show({ title: 'Failed!', message: JSON.stringify(err.request) || 'Report could not be shared.', color: errorNotificationColor })
        } else {
            console.log("Message: " + err.message);
            notifications.show({ title: 'Failed!', message: 'Report could not be shared.', color: errorNotificationColor })
        }
        throw err;
    }
}

export async function getJsReport({ templateId, data }: { templateId: string; data: { [K: string]: any; } }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}jsReport/report/html`, {
            templateID: templateId,
            data,
        });
        console.log(res.data.status);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err} ${err.response.data.message}`);
        throw new Error(err.response.data.message);
    }
}

//Re-calculate (aut-generate) DPR daily targets API
export async function reCalculateTargets({ projectId, targetPeriod }: { projectId: string; targetPeriod: string }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}dpr/apis/wbs/autoGenerate`, {
            projectId,
            targetPeriod,
        });
        console.log(res.data);
        notifications.show({ title: 'Success', message: `Targets have been re-calculated successfullys!`, color: successNotificationColor });
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err} ${err.response.data.message}`);
        notifications.show({ title: 'Failed', message: err.response.data.message || 'Targets could not be re-calculated!', color: errorNotificationColor })
        throw new Error(err.response.data.message);
    }
}


/**New Kotlin send mail API */
export async function sendMail({ emailPayload }: {
    emailPayload: {
        emailSubject: string,
        emailBody: string,
        recipients: string[],
        attachments?: { url: string, filename: string, type: string, disposition: string }[] | { binaryContent: string, filename: string, type: string, disposition: string }[],
        customerId?: string,
        unitActivityId?: string,
        senderId?: string,
        senderName?: string
    }
}) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}email/send`, {
            ...emailPayload, senderId: 'no-reply@support.falconbrick.com', senderName: 'Falconbrick Reports'
        });
        // console.log(res.data.status);
        notifications.show({ title: 'Shared mail!', message: `Email sent successfully.`, color: successNotificationColor });
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err} ${err.response.data.message}`);
        notifications.show({ title: 'Failed!', message: `Failed to send email.`, color: errorNotificationColor });
        throw new Error(err.response.data.message);
    }
}

/**Get type definitions for task updates */
export async function getProcessDefinitionsByProject({ projectId, moduleId }: { projectId: string, moduleId: string }) {
    try {
        const res = await axios.get(config.TransactionServerUrl + `process-engine/user-tasks/active?projectId=${projectId}&moduleId=${moduleId}`);
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

/**To export task forms to Excel file */
export async function downloadRequestTaskForms({ tenant, projectId, moduleId, ticketTypeId, fileName, taskIds }: { tenant: string; projectId: string; moduleId: string; ticketTypeId: string; fileName: string; taskIds: string }) {
    try {
        // const filename = fileName || (ticketTypeId + ".xlsx");
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/export-task-form/${tenant}/${projectId}/${moduleId}/${ticketTypeId}?fileName=${fileName}&taskIds=${taskIds}`, {
            responseType: 'blob'
        });
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

/**To import task forms from Excel file */
export async function importRequestTaskForms({ tenant, projectId, moduleId, file }: { tenant: string; projectId: string; moduleId: string; file: File; }) {
    try {
        let form = new FormData();
        form.append('file', file);
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/import-task-form/${tenant}/${projectId}/${moduleId}`,
            form,
            {
                headers: { "Content-Type": "multipart/form-data" },
                responseType: 'blob'
            }
        );

        // // create file link in browser's memory
        const href = URL.createObjectURL(res.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', file.name); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        notifications.show({ title: 'Uploaded!', message: `File uploaded successfully. Check "Results" column in the file for each` })
        // return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'File could not be uploaded.', color: errorNotificationColor })
        throw err;
    }
}

export async function regenerateChecklistReport({ customerId, projectId, propertyId, activityTypeId, untiActivityId, fromNode, toNode }: { customerId: string; projectId: string; propertyId: string; activityTypeId: string; untiActivityId: string; fromNode: number; toNode: number; }) {
    try {
        let payload = {};
        const __splitUrl = config.TransactionServerUrl?.split("-") || [];
        const url = __splitUrl.slice(0, -1).join("-").concat("-", __splitUrl.slice(-1)[0].replace(/([^.]+)/, '$1-admin'));
        payload['customer_id'] = customerId;
        payload['project_id'] = projectId;
        payload['property_id'] = propertyId;
        payload['activity_type_id'] = activityTypeId;
        payload['unit_activity_id'] = untiActivityId;
        // payload['from_node'] = fromNode.toString();
        // payload['to_node'] = toNode.toString();
        payload['from_node'] = fromNode;
        payload['to_node'] = toNode;
        const res = await axios.post(`${url}adminTools/generateCertificate`,
            { ...payload, type: "unit-act-move" },
            { headers: { "Content-Type": "application/json" } }
        );
        notifications.show({ title: 'Success!', message: 'Report was regenerated successfully!' })
        console.log(res.data);
        // await new Promise((rs, _) => setTimeout(() => { rs(null); }, 2000));
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'Report regeneration failed.', color: errorNotificationColor })
        throw err;
    }
}

export interface IUnitActivityUpdatePayload {
    projectId: string;
    unitActivityId: string;
    planStart?: string;
    planEnd?: string;
    description?: string;
    isDPR?: boolean;
    plannedTotalQuantity?: number;
};


//Unit-activity update API
export async function unitActivityUpdate({ payload }: { payload: IUnitActivityUpdatePayload; }) {
    try {
        const res = await axios.put(`${config.TransactionServerUrl}common/apis/unitActivity`, payload);
        return res.data;
    }
    catch (error) {
        if (error.response) {
            if (error.response.data) {
                console.log({ ...error.response.data, status: error.response.status });
            } else {
                console.log({ ...error.message, status: error.response.status });
            }
        } else if (error.request) {
            console.log({ ...error.request });
        } else {
            console.log({ ...error.message });
        }
    }
}

export async function downloadUAUpdateTemplateFile({ projectId }: { projectId: string }) {
    try {

        let form = new FormData();
        form.append('projectId', projectId);
        // const res = await axios.post(`${config.TransactionServerUrl}process-engine/importTickets/${tenant}/${projectId}/${moduleId}/${ticketTypeId}`,
        //     form,
        //     { headers: { "Content-Type": "multipart/form-data" } }
        // );

        const res = await axios.post(`${config.TransactionServerUrl}dpr/apis/export/excel/wbsDetails`, {
            form,
            headers: { "Content-Type": "multipart/form-data" },
            responseType: 'blob',
            // responseType: 'blob'
        });
        // console.log("res", res, res.statusText);
        if (res.statusText.toLowerCase() === "ok") {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Activity_data_template'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        return res.statusText;
    }
    catch (err) {
        console.error(`Error response: ${err} ${JSON.stringify(err.response)}`);
        throw err;
    }
}

export async function importUAUpdateTemplateFile({ projectId, file }: { projectId: string; file: File; }) {
    try {
        let form = new FormData();
        form.append('file', file);
        form.append('projectId', projectId);
        const res = await axios.post(`${config.TransactionServerUrl}dpr/apis/import/excel/wbsSetup`,
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        notifications.show({ title: 'Uploaded!', message: 'File uploaded successfully.' })
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'File could not be uploaded.', color: errorNotificationColor })
        throw err;
    }
}

// Common API fetch by entities
export async function commonFetchByEntity({ entity, filters }: { entity: CommonApiEntities; filters?: { [K: string]: any; }}) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}common/apis/${entity}${filters ? `?${Object.keys(filters).map(k => `${k}=${filters[k]}`).join("&")}` : ""}`);
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

//Excel template download for Unit Activity create
export async function downloadUnitActivityTemplate({ fileName }: { fileName: string; }) {
    try {
        const filename = fileName || ('template_' + new Date().toString() + ".xlsx");
        const res = await axios.get(`${config.TransactionServerUrl}dpr/apis/export/template/excel/wbsSetup`, {
            responseType: 'blob'
        });
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
    catch (error) {
        if (error.response) {
            if (error.response.data) {
                console.log({ ...error.response.data, status: error.response.status });
            } else {
                console.log({ ...error.message, status: error.response.status });
            }
        } else if (error.request) {
            console.log({ ...error.request });
        } else {
            console.log({ ...error.message });
        }
    }
}

//To import Unit activity Excel file
export async function importUnitActivities({ projectId, propertyId, file }: { projectId: string; propertyId?: string; file: File; }) {
    try {
        let form = new FormData();
        form.append('projectId', projectId);
        form.append('file', file);
        if (propertyId) { form.append('propertyId', propertyId) }
        const res = await axios.post(`${config.TransactionServerUrl}dpr/apis/import/excel/wbsSetup`,
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        notifications.show({ title: 'Uploaded!', message: 'File uploaded successfully. Track the status of the Job below.', color: successNotificationColor })
        // console.log(res.data);
        return res.data;
    }
    catch (error) {
        if (error.response) {
            if (error.response.data) {
                console.log({ ...error.response.data, status: error.response.status });
                notifications.show({ title: 'Failed!', message: JSON.stringify(error.response.data) || 'File could not be uploaded.', color: errorNotificationColor })
            } else {
                console.log({ ...error.message, status: error.response.status });
                notifications.show({ title: 'Failed!', message: JSON.stringify(error.message) || 'File could not be uploaded.', color: errorNotificationColor })
            }
        } else if (error.request) {
            console.log({ ...error.request });
            notifications.show({ title: 'Failed!', message: JSON.stringify(error.request) || 'File could not be uploaded.', color: errorNotificationColor })
        } else {
            console.log({ ...error.message });
            notifications.show({ title: 'Failed!', message: 'Sorry! File could not be uploaded.', color: errorNotificationColor })
        }
    }
}

//To download unit activity data by passing different levels of filters - project &  activity
export async function downloadUnitActivityData({ projectId, propertyId, activities, fileName }: { projectId: string; propertyId?: string; activities?: string[], fileName: string }) {
    try {
        // let form = new FormData();
        let payload = {};
        // form.append('projectId', projectId);
        // form.append('fileName', fileName)
        // if (propertyId) { form.append('propertyId', propertyId); }
        // if(fileName) {form.append('fileName', fileName);}
        // if(activities?.length) { form.append('activities', JSON.stringify(activities)); }
        payload = { projectId, fileName }
        const res = await axios.post(`${config.TransactionServerUrl}dpr/apis/export/excel/wbsDetails`,
            payload,
            {
                headers: { "Content-Type": "application/json" },
                responseType: 'blob'
            }
        );

        // // create file link in browser's memory
        const href = URL.createObjectURL(res.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        notifications.show({ title: 'Success!', message: `File downloaded successfully.` })
        // return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'File could not be dowloaded.', color: errorNotificationColor })
        throw err;
    }
}

//To import Unit activity Excel file for partial updates (planned dates & Total qty)
export async function importUnitActivitiesForPartialUpdates({ projectId, propertyId, file }: { projectId: string; propertyId?: string; file: File; }) {
    try {
        let form = new FormData();
        form.append('projectId', projectId);
        form.append('file', file);
        if (propertyId) { form.append('propertyId', propertyId) }
        const res = await axios.post(`${config.TransactionServerUrl}dpr/apis/import/excel/wbsUpdate`,
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        notifications.show({ title: 'Uploaded!', message: 'File uploaded successfully. Track the status of the Job below.', color: successNotificationColor })
        // console.log(res.data);
        return res.data;
    }
    catch (error) {
        if (error.response) {
            if (error.response.data) {
                console.log({ ...error.response.data, status: error.response.status });
                notifications.show({ title: 'Failed!', message: JSON.stringify(error.response.data) || 'File could not be uploaded.', color: errorNotificationColor })
            } else {
                console.log({ ...error.message, status: error.response.status });
                notifications.show({ title: 'Failed!', message: JSON.stringify(error.message) || 'File could not be uploaded.', color: errorNotificationColor })
            }
        } else if (error.request) {
            console.log({ ...error.request });
            notifications.show({ title: 'Failed!', message: JSON.stringify(error.request) || 'File could not be uploaded.', color: errorNotificationColor })
        } else {
            console.log({ ...error.message });
            notifications.show({ title: 'Failed!', message: 'Sorry! File could not be uploaded.', color: errorNotificationColor })
        }
    }
}


export async function CheckAndDownloadUnitActiivtyStatus({ filename, reqId }: { filename: string; reqId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}dpr/apis/fetchImportedWbsFile?requestId=${reqId}`, {
            responseType: 'blob'
        });
        // console.log("res", res, res.statusText);
        if (res.statusText.toLowerCase() === "ok") {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        return res.statusText;
    }
    catch (err) {
        console.error(`Error response: ${err} ${JSON.stringify(err.response)}`);
        throw err;
    }
}
// curl --location 'http://localhost:8080/common/apis/initUnitActivity' \
// --header 'Authorization: Bearer TOKEN' \
// --header 'Content-Type: application/json' \
// --data '{
//     "projectId": "mang",
//     "unitActivityId": "b1b29ecb-400b-44e2-a68c-1365823f5997"
// }'

export async function unitActivityStart({ ...rest }: { projectId: string; unitActivityId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}common/apis/initUnitActivity`, { ...rest });
        notifications.show({ title: 'Started!', message: 'Unit Activity Started successfully.', color: successNotificationColor })
        return res.data;
    }
    catch (error) {
        if (error.response) {
            if (error.response.data) {
                console.error({ ...error.response.data, status: error.response.status });

            } else {
                console.error({ ...error.message, status: error.response.status });
            }
        } else if (error.request) {
            console.error({ ...error.request });
        } else {
            console.error({ ...error.message });
        }
        notifications.show({ title: 'Failed!', message: error.response?.data ? JSON.stringify(error.response.data) : 'Unit Activity could not be started.', color: errorNotificationColor })
        throw error;
    }
}

//Common API create by entity
export async function commonCreateByEntity({ entity, data }: { entity: CommonApiEntities; data: { [K: string]: any; }}) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}common/apis/${entity}`, data);
        // console.log(res.data);
        notifications.show({ title: 'Success', message: `${entity} created successfully!`, color: successNotificationColor })
        return res.data;
    }
    catch (error) {
        showErrorNotification(error, `${entity} create failed`);
        throw error;
    }
}

//Common API update by entity
export async function commonUpdateByEntity({ entity, data }: { entity: CommonApiEntities; data: { [K: string]: any; }}) {
    try {
        const res = await axios.put(`${config.TransactionServerUrl}common/apis/${entity}`, data);
        // console.log(res.data);
        notifications.show({ title: 'Success', message: `${entity} updated successfully!`, color: successNotificationColor })
        return res.data;
    }
    catch (error) {
        showErrorNotification(error, `${entity} update failed`);
        throw error;
    }
}

//Common API delete by entity
export async function commonDeleteByEntity({ entity, data }: { entity: CommonApiEntities; data: { [K: string]: any; }}) {
    try {
        const res = await axios.delete(`${config.TransactionServerUrl}common/apis/${entity}`, { data });
        notifications.show({ title: 'Success', message: `${entity} deleted successfully!`, color: successNotificationColor })
        return res.data;
    }
    catch (error) {
        showErrorNotification(error, `${entity} delete failed`);
        throw error;
    }
}

//Patch for status updates by entity
export async function commonPatchByEntity({ entity, data }: { entity: CommonApiEntities; data: { [K: string]: any } }) {
    try {
      const res = await axios.patch(`${config.TransactionServerUrl}common/apis/${entity}`, data);
      notifications.show({ title: `${entity} status updated`, message: `${entity} has been marked ${data.enable ? 'Active' : 'Inactive'}`});
      return res.data;
    } 
    catch (error) {
        showErrorNotification(error, `${entity} status update failed`);
        throw error;
    }
  }

  export async function getUserPassword({ entity, id }: { entity: CommonApiEntities.USERS; id: string }) {
    try {
      const res = await axios.get(`${config.TransactionServerUrl}common/apis/${entity}/password?userId=${id}`);
      return res.data;
    } 
    catch (error) {
        showErrorNotification(error, `${entity} password fetch failed`);
        throw error;
    }
  }

//Excel template for bulk user action
export async function downloadUserExcelTemplate() {
    try {
        let fileName = `Bulk_Users_Creation_Format.xlsx`;
        const res = await axios.get(`${config.TransactionServerUrl}files/download?fileType=document&fileName=${fileName}`, {
            responseType: 'blob'
        });
        // console.log("res", res, res.statusText);
        if (res.statusText.toLowerCase() === "ok") {
            return res.data;
        }
        // showErrorNotification('error', `Template download failed!`);
        throw new Error('something went wrong.. dwnld failed');
    }
    catch (error) {
        showErrorNotification(error, `Template download failed!`);
        throw error;
    }
}


export async function importUserBulkExcel({ customerId, file }: { customerId: string; file: File; }) {
    try {
        let form = new FormData();
        form.append('customerId', customerId);
        form.append('file', file);
        const res = await axios.post(`${config.TransactionServerUrl}common/apis/user/bulk`,
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        notifications.show({ title: 'Success!', message: 'File uploaded successfully. Check import history for more info.', color: successNotificationColor })
        // console.log(res.data);
        return res.data;
    }
    catch (error) {
        showErrorNotification(error, `Sorry! File could not be uploaded.`);
        throw error;
    }
}