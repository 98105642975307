import React from 'react';
import noDataIcon from '../../assets/images/nodata.png';

const NoDataFound = ({ title, titleStyle }: { title?: string, titleStyle?: { [K: string]: any } }) => {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      margin: "auto",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.29,
      letterSpacing: "normal",
      flexFlow: "column"
    }}><img src={noDataIcon} alt="nodata" /><div>
        {titleStyle ?
          <p style={{ ...titleStyle }}>
            {title || `Sorry. There's nothing to show at this moment.`}
          </p> :
          <p>
            {title || `Sorry. There's nothing to show at this moment.`}
          </p>
        }
      </div> </div>
  )
}

export default NoDataFound