import React, { useState, useEffect } from 'react';
import { Textarea } from '@mantine/core';

const JsonInputComponent = ({ field, value, setFormValues, fieldType, handleJsonValidation, disabled }) => {
    const [inputValue, setInputValue] = useState(JSON.stringify(value, null, 2));
    const [error, setError] = useState('');

    useEffect(() => {
        setInputValue(JSON.stringify(value, null, 2)); // Sync with parent updates
    }, [value]);

    // 🔹 Helper functions for validation
    const isValidJsonObject = (value) => typeof value === 'object' && !Array.isArray(value) && value !== null;
    const isValidJsonArray = (value) => Array.isArray(value);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);

        try {
            const parsedValue = JSON.parse(newValue);

            // 🚨 Validate based on fieldType
            if (fieldType === 'jsonObject' && !isValidJsonObject(parsedValue)) {
                throw new Error('Expected an object {}');
            }
            if (fieldType === 'jsonArray' && !isValidJsonArray(parsedValue)) {
                throw new Error('Expected an array []');
            }

            setFormValues((prevValues) => ({
                ...prevValues,
                [field.id]: parsedValue, // ✅ Only set valid values
            }));

            setError(''); // Clear error
            handleJsonValidation(field.id, true);
        } catch (err) {
            setError(err.message || 'Invalid JSON format'); // 🚨 Show error
            handleJsonValidation(field.id, false, err.message);
        }
    };

    return (
        <Textarea
            label={field.name}
            disabled={disabled}
            value={inputValue} // Convert array to newline-separated text
            placeholder={`Enter ${field.name} (one per line)`}
            onChange={handleChange}
            required={field.mandatory}
            minRows={3} // Keep at least 3 rows
            error={error}
            autosize
            size="sm"
            styles={{
                label: { fontSize: '0.85em', color: '#585858', fontWeight: 600 },
                input: {
                    fontSize: '0.8525em',
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                    border: '0.0525em solid #585858',
                    borderRadius: 0,
                    '&[data-disabled]': { border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' },
                },
            }}
        />
    );
};

export default JsonInputComponent;
