import React, { useCallback } from 'react';
import { Button, Group, Tooltip } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../models/ProvideModel';
import { MRT_TableInstance, createRow } from 'mantine-react-table';
import { PropertyDetails } from './gridColumns';
import { IconPlus, IconRefresh } from '@tabler/icons-react';
import { CommonApiEntities } from '../../../models/enums';

const PropertyToolbarComponent = ({ table }: { table: MRT_TableInstance<PropertyDetails> }) => {
    const store = useStore();
    const buttonStyle = { borderRadius: 0 }

    const createNewRow = useCallback(() => {
        table.setPageIndex(0);
        table.refs.tableContainerRef.current.scroll(0, 0);
        const row = createRow(table);
        table.setCreatingRow(row);
        if (!table.getState().columnVisibility['mrt-row-actions']) {
            table.setColumnVisibility({ 'mrt-row-actions': true });
        }
    }, []);


    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <span style={{ fontSize: "1.7em", display: "flex", justifyContent: "flex-start", fontWeight: 700, color: "#2F3E6C" }}>PROPERTIES &nbsp;
                <span style={{ fontSize: '0.7em', color: '#C9C9C9', alignSelf: 'center' }}>{`(${store.configManager.filteredRowsCount})`}</span>
            </span>
            <Group spacing="xs">
                <Button
                    size="xs"
                    leftIcon={<IconPlus width={'1.5em'} height={'1.5em'} />}
                    onClick={createNewRow}
                    color='teal'
                    style={buttonStyle}
                >
                    Add New Property
                </Button>
                <Tooltip
                    label={'Refresh data'}
                >
                    <Button
                        size="xs"
                        leftIcon={<IconRefresh width={'1.5em'} height={'1.5em'} />}
                        onClick={() => store.configManager.fetchEntityData(CommonApiEntities.PROPERTIES)}
                        style={buttonStyle}
                    >
                        Refresh
                    </Button>
                </Tooltip>
            </Group>
        </div>
    );
};

export default observer(PropertyToolbarComponent);
