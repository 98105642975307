import React, { useMemo } from "react";
import { Button, Group, Tooltip } from "@mantine/core";
import { MRT_ColumnDef } from "mantine-react-table";
import { Instance } from "mobx-state-tree";
import { LateStoreModel } from "../../../models/DataStore";
import { CommonApiEntities } from "../../../models/enums";
import moment from "moment";
import PropertyCell from "./customCells/PropertyCell";

export interface BaseBlockColumns {
    id: string,
    customerId?: string
    name: string,
    description?: string
    propertyId: string,
    syncCt?: string,
    syncUt?: string,
};

export interface BlockDetails extends BaseBlockColumns {
    [K: string]: any;
};

export const useBlockTableColumnDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<BlockDetails>[] = (store) => useMemo(() => [
    {
        accessorKey: "id",
        header: "Block ID",
        enableEditing: true,
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => {
            const isCreateMode = !row.original.id
            return {
                value: store.configManager.currentlyEditingRow?.id ?? row.original.id ?? "",
                disabled: !isCreateMode,
                onChange: (e) => {
                    if (isCreateMode) {
                        store.configManager.setEditingRow({ id: e.currentTarget.value });
                    }
                }
            };
        },

    },
    {
        accessorKey: "name",
        header: "Block Name",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.name ?? row.original.name ?? "",
            onChange: (e) => store.configManager.setEditingRow({ name: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "description",
        header: "Description",
        enableColumnFilterModes: false,
        mantineEditTextInputProps: ({ row }) => ({
            value: store.configManager.currentlyEditingRow?.description ?? row.original.description ?? "",
            onChange: (e) => store.configManager.setEditingRow({ description: e.currentTarget.value }),
        }),
    },
    {
        accessorKey: "propertyId",
        header: "Property",
        enableColumnFilterModes: false,
        Cell: ({ row }) => {
            const property = store.configManager.properties.find(p => p.id === row.original.propertyId);
            const project = store.configManager.projects.find(proj => proj.id === property?.projectId);
            return property
              ? `${property.name} (${project?.name || 'Unknown Project'})`
              : row.original.propertyId;
          },
        Edit: ({row}) => <PropertyCell row={row}/>
    },
    {
        accessorKey: "syncUt",
        header: "Updated On",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => {
            const dateStr = row.original.syncUt;
            return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
        },
    },
    {
        accessorKey: "syncCt",
        header: "Created On",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => {
            const dateStr = row.original.syncCt;
            return <span>{dateStr ? moment(dateStr).format("YYYY-MM-DD HH:mm:ss") : "-"}</span>;
        },
    },
    {
        accessorKey: "actions",
        header: "Actions",
        enableColumnFilterModes: false,
        enableEditing: false,
        Cell: ({ row }) => (
            <Group spacing="xs">
                <Button
                    size="xs"
                    color="red"
                    variant="outline"
                    disabled={!row.original.id}
                    style={{ borderRadius: 0 }}
                    onClick={() => store.configManager.deleteEntityItem(CommonApiEntities.BLOCKS, row.original.id)}
                >
                    Delete
                </Button>
            </Group>
        ),
    },
], []);