import { Group, Loader, Stack, Text } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IBulkImport } from '../../models/Planning';
import { notifications } from '@mantine/notifications';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import { UnitActivityBulkActions } from '../../models/enums'

const MAX_SIZE = 25 * 1024 ** 2;

const UploadDropZone = ({ model, label, caption, actionType, report }: { model: IBulkImport; label: string; caption: string, actionType: string, report?: string }) => {
    return (
      <Stack w="100%" spacing="xs">
        <Group position="apart"
         align="center" >
          <Text
            fw={600}
            // my="0.5em"
            mb={'0.1em'}
            fz="0.9525em"
            c="#000"
            opacity="1"
          >{label}
            <span style={{ color: "red", marginLeft: "0.25em" }}>*</span>
          </Text>
          <Text
            fw={400}
            // my="0.5em"
            mb={'0.5em'}
            fz="0.6525em"
            c="#585858"
            opacity="0.8"
          >{caption}
          </Text>
        </Group>
        <Dropzone
          onDrop={(files) => {
            // console.log('accepted files', files, 'size', files[0].size, 'MAX_SIZE', MAX_SIZE);
            if (files[0].size < MAX_SIZE) { 
              if(actionType === UnitActivityBulkActions.CREATE) {
                model.makeBulkImportRequest(files[0], UnitActivityBulkActions.CREATE)
              } else {
                model.makeBulkImportRequest(files[0], UnitActivityBulkActions.MODIFY, report)
              }
            }
          }}
          onReject={(files) => {
            console.log('rejected files', files);
            notifications.show({ title: 'Failed!', message: `Maximum file upload size is 25 MB.`, color: 'red', icon: <IconX /> })
          }}
          name={label}
          activateOnClick
          accept={[MIME_TYPES.xlsx]}
          w="100%"
          styles={{
            inner: { pointerEvents: "all", cursor: "pointer" },
            root: {
              borderRadius: 0,
              border: '0.1em dashed #005eff',
            }
          }}
          maxSize={MAX_SIZE}
          // disabled={model.disabled}
        //   disabled={disabled}
        >
          {model.loading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Loader size={'xs'} />
            </div> : (
              <Group position="center" spacing="xl" style={{ pointerEvents: 'none', alignSelf: 'baseline', }}>
                <Dropzone.Accept>
                  <IconUpload
                    size="1.2em"
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    size="1.2em"
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconPhoto size="1.2em" stroke={1.5} />
                </Dropzone.Idle>
                <div>
                  <Text size="xs" inline>
                    { "Drag file here or click to select a file" }
                  </Text>
                </div>
              </Group>)
          }
        </Dropzone>
      </Stack >
    );
  };

export default observer(UploadDropZone);